import { useCallback, useMemo } from 'react';
import { ReactQueryCommonUseQueryOptions, VQueryKey } from "../../../sharedReact/src/hooks/queryHookHelpers";
import { ApiGuid, Employee, GetThingListOptions, Job, ServiceItem, ServiceItemForCreate, ServiceItemForCreateOrUpdate, ServiceItemForUpdate, ServiceItemQueryOptions, ServiceItemRateRule, ServiceItemRateRuleEngineLookupFunction, ServiceItemRateRuleForCreate, ServiceItemRule, ServiceItemRuleEngineLookupFunction, ServiceItemRuleForCreate } from '../../../vericlock_api/src/types';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { BaseUseThingListProps, ThingListActiveInactiveFetchOption, ThingListDeletedFetchOptions, useThingList, UseThingListProps } from '../../../sharedReact/src/hooks/useThingList';
import { ReactQueryExtractErrorString } from '../../../sharedReact/src/hooks/queryHookHelpers'
import { createServiceItemRuleEngine } from '../../../lib/src/RuleEngines/RuleEngineServiceItem';
import { useVeriClockApi } from '../../../sharedReact/src/hooks/ApiProvider';
import { createServiceItemRateRuleEngine } from '../../../lib/src/RuleEngines/RuleEngineServiceItemRates';
import { canEmployeesAccessServiceItem, canJobsAccessServiceItem } from '../../../lib/src/RuleEngines/ServiceItemAccessControl';
import { useCompanyLoaded } from './companyHooks';

// WARNING: the cached version of the current employee does not come from node, it's the PHP object
// which has some differences.  When in doubt, skip the cache and fetch directly from node with
// useCache = false
export function useServiceItem(serviveItemGuid:ApiGuid|null|undefined)
{
    // console.warn('note - this is loading all active/inactive/deleted THEN deleted...odd');
    const sMain = useServiceItemList({
        statusToFetch: ['active','inactive'], 
        enabled: serviveItemGuid != null  
    });

    //check main list first
    let serviceItem = sMain.serviceItems.find(g => g.guid === serviveItemGuid);    
    //flag to load deleted items, if item isn't found AFTER the list is queried
    const lookInTheTrash = serviveItemGuid ? (sMain.isFetched && !serviceItem) : false;

    //only load/check the deleted item, if the activeInactive loaded and it wasn't in there.
    const sDel = useServiceItemList({
        statusToFetch: ['deleted'], 
        enabled: lookInTheTrash  
    });

    if(lookInTheTrash)
    {
        serviceItem = sDel.serviceItems.find(g => g.guid === serviveItemGuid);        
    }

    //combined isLoading
    const isLoading = serviveItemGuid ? (sMain.isLoading || lookInTheTrash && sDel.isLoading) : false;
    //combined loadError
    let loadError = null;
    if(serviveItemGuid)
    {
        const errs:string[] = [];
        if(sMain.loadError)
            errs.push(sMain.loadError)
        if(lookInTheTrash && sDel.loadError)
            errs.push(sDel.loadError);
        if(errs.length)
            loadError = errs.join(','); 
    }

    return {
        serviceItem,
        isLoading,
        isFetched:sMain.isFetched,
        loadError,
        update:sMain.update,
        create:sMain.create
    }
}

const commonUseQueryOptionsServiceItems:UseQueryOptions<ServiceItem[]> = {
    ...ReactQueryCommonUseQueryOptions
}
//NOTE by making these constants, the queries are safely cached - RQ treats array order as a unique key, so 'a','b' != 'b','a'

export type UseGroupListProps = BaseUseThingListProps;
const activeInactiveQueryKey:UseThingListProps<ServiceItem>['activeInactiveQueryKey'] = [VQueryKey.ServiceItems, ThingListActiveInactiveFetchOption, false];
const deletedQueryKey:UseThingListProps<ServiceItem>['deletedQueryKey'] = [VQueryKey.ServiceItems, ThingListDeletedFetchOptions, false];
const activeInactiveQueryKeyTeamView:UseThingListProps<ServiceItem>['activeInactiveQueryKey'] = [VQueryKey.ServiceItems, ThingListActiveInactiveFetchOption, true];
const deletedQueryKeyTeamView:UseThingListProps<ServiceItem>['deletedQueryKey'] = [VQueryKey.ServiceItems, ThingListDeletedFetchOptions, true];

type PrecomputedHelp<D> =
{
    guidToChildren: Record<ApiGuid, {
        children: D[],
    }>
}
export function useServiceItemList(props:UseGroupListProps)
{    
    const { api } = useVeriClockApi();
    const fetchListCallback = useCallback(async (options:GetThingListOptions) => {
        
        const searchOptions:ServiceItemQueryOptions = 
        {
            status: options.status,
            teamView: options.teamView      
        }
    
        return (await api.queryServiceItems(searchOptions)).payload;
    },[api]);


    const groupUseThingListResp = useThingList({
        ...props,
        fetchListCallback,
        commonUseQueryOptions: commonUseQueryOptionsServiceItems,
        activeInactiveQueryKey: props.teamView ? activeInactiveQueryKeyTeamView : activeInactiveQueryKey,
        deletedQueryKey: props.teamView ? deletedQueryKeyTeamView : deletedQueryKey,
        type:'serviceItem'  
    });
    const { updateCache, getByGuid, things, ...restUseThingsResp } = groupUseThingListResp; //.updateCache;


    const preComputedHelp = useMemo(() => {
        let data:PrecomputedHelp<ServiceItem> = {
            guidToChildren: {}
        }
        things.forEach(item => {
            if(item.parentGuid)
            {
                if(!data.guidToChildren[item.parentGuid]) { //init the data structure if we haven't seen this parent yet
                    data.guidToChildren[item.parentGuid] = {
                        children:[]
                    }
                }
                data.guidToChildren[item.parentGuid].children.push(item); //add this job to the children jobs list 
                //future: additioanl helpers, like a map, or a map/list of all descendants from this job..etc.
            }
        });
        return data;
    },[things]);

    const getChildren = useCallback( (item:ServiceItem) => {
        let childrenStructure = preComputedHelp.guidToChildren[item.guid]
        if(childrenStructure)
            return childrenStructure.children;
        return []; //no children if not found (or they could be deleted)
    },[preComputedHelp]);

    const updateChildrenToNewStatusInCache = useCallback( (item:ServiceItem) => {
        const children = getChildren(item);
        children.forEach(child => {
            
            if(child.status != item.status && //only alter children (and descendants) if children are more active than new status
                ((child.status === 'active' && (item.status === 'inactive' || item.status === 'deleted'))
                || (child.status === 'inactive' && item.status === 'deleted')))
            {
                updateCache([{...child, status: item.status }], { [child.guid]: child });
                //also do the children
                updateChildrenToNewStatusInCache(child);
            }             
        });
    },[getChildren, updateCache]);
    const update = useCallback(async (item:ServiceItemForUpdate, oldItem:ServiceItem) => {
        const updatedItem = (await api.updateServiceItem(item)).payload;
        updateCache([updatedItem], { [oldItem.guid]: oldItem });
        //handle decendents
        if(oldItem.status != updatedItem.status &&
            ((oldItem.status === 'active' && (updatedItem.status === 'inactive' || updatedItem.status === 'deleted'))
            || (oldItem.status === 'inactive' && updatedItem.status === 'deleted')))
        {
            updateChildrenToNewStatusInCache(updatedItem);
        }

        return updatedItem;        
    },[api, updateCache, updateChildrenToNewStatusInCache]);
    
    const create = useCallback(async (serviceItem:ServiceItemForCreate) => {        
        let createdGroup = (await api.createServiceItem(serviceItem)).payload;
        updateCache([createdGroup], {}); //empty map, signals JUST a create
        return createdGroup;        
    },[api, updateCache]);

    const createUpdateList = useCallback(async (serviceItems:ServiceItemForCreateOrUpdate[], oldMap:Record<ApiGuid, ServiceItem>) => {
        const r = await api.createUpdateServiceItemList({ serviceItems });
        updateCache(r.payload, oldMap);
        return r.payload;
    },[api,updateCache]);

    return {
        ...restUseThingsResp,
        serviceItems: things,
        createUpdateList,
        update,
        create,
        getByGuid
    }
}

const commonUseQueryOptionsServiceItemRules:UseQueryOptions<ServiceItemRule[]> = {
    ...ReactQueryCommonUseQueryOptions
}
export function useServiceItemRules(props?:{enabled?:boolean})
{
    const options = props ?? {};
    const { enabled=true } = options;
    const { api } = useVeriClockApi();
    const queryClient = useQueryClient();
    const query = useQuery<ServiceItemRule[]>(VQueryKey.ServiceItemRules, async () => (await api.getServiceItemRules()).payload, {
        enabled,
        ...commonUseQueryOptionsServiceItemRules
    });

    const findRuleIndex = useCallback( (rule:ServiceItemRuleForCreate) => {

        let ruleList = queryClient.getQueryData<ServiceItemRule[]>(VQueryKey.ServiceItemRules); 
        if(!ruleList) //no list, there for not in the list
            return -1;

        return ruleList.findIndex(r => {
            return r.employee === rule.employee &&
                r.group === rule.group &&
                r.job === rule.job;
        });
    },[queryClient]);

    const deleteRule = useCallback( async (rule:ServiceItemRule) => {
        await api.deleteServiceItemRule(rule);
        
        let ruleList = queryClient.getQueryData<ServiceItemRule[]>(VQueryKey.ServiceItemRules); 
        if(!ruleList) //wasn't in the list...odd, ignore
            return;

        //rule is identified by the contents - they are collectively unique
        let index = ruleList.findIndex(r => {
            return r.employee === rule.employee &&
                r.group === rule.group &&
                r.job === rule.job &&
                r.serviceItem === rule.serviceItem;
        });
        if(index >= 0) //if found, remove from list and reset the value of the query
        {
            ruleList = [...ruleList];
            ruleList.splice(index, 1);
            queryClient.setQueryData<ServiceItemRule[]>(VQueryKey.ServiceItemRules, ruleList); 
        }
        
    },[queryClient, api]);

    const createRule = useCallback( async (rule:ServiceItemRuleForCreate) => {
        let resp = await api.createServiceItemRule(rule);
        let newRule = resp.payload
        
        let ruleList = queryClient.getQueryData<ServiceItemRule[]>(VQueryKey.ServiceItemRules); 
        if(!ruleList)
            ruleList = [newRule];
        else
            ruleList = [...ruleList, newRule]; //new array ref

        queryClient.setQueryData<ServiceItemRule[]>(VQueryKey.ServiceItemRules, ruleList); 
        return newRule;
    },[queryClient,api]);

    const ruleEngine:ServiceItemRuleEngineLookupFunction = useMemo( () => {
        return createServiceItemRuleEngine(query.data);
    },[query.data]);

    const serviceItemRules = query.data;
    const lookup = useMemo( () => {
        const quickLookupByJobGuid:Record<ApiGuid, ServiceItemRule[]> = {};
        const quickLookupByEmployeeGuid:Record<ApiGuid, ServiceItemRule[]> = {};
        const quickLookupByGroupGuid:Record<ApiGuid, ServiceItemRule[]> = {};
        const quickLookupByServiceItemGuid:Record<ApiGuid, ServiceItemRule[]> = {};
        
        if(serviceItemRules)
        {
            serviceItemRules.forEach(rule => {
                if(rule.job != 'any')
                {
                    if(!quickLookupByJobGuid[rule.job])
                        quickLookupByJobGuid[rule.job] = [];
                    quickLookupByJobGuid[rule.job].push(rule);  //add the rule to the job's rule array
                }
                if(rule.employee != 'any')
                {
                    if(!quickLookupByEmployeeGuid[rule.employee])
                        quickLookupByEmployeeGuid[rule.employee] = [];
                    quickLookupByEmployeeGuid[rule.employee].push(rule);  //add the rule to the job's rule array
                }
                if(rule.group != 'any')
                {
                    if(!quickLookupByGroupGuid[rule.group])
                        quickLookupByGroupGuid[rule.group] = [];
                    quickLookupByGroupGuid[rule.group].push(rule);  //add the rule to the job's rule array                    
                }
                if(rule.serviceItem != 'none' && rule.serviceItem != 'required' && rule.serviceItem != 'optonal')
                {
                    if(!quickLookupByServiceItemGuid[rule.serviceItem])
                        quickLookupByServiceItemGuid[rule.serviceItem] = [];
                    quickLookupByServiceItemGuid[rule.serviceItem].push(rule);  //add the rule to the job's rule array
                }
            })
        }
        
        const getRulesByJobGuid = (guid:ApiGuid):ServiceItemRule[]|undefined => {
            return quickLookupByJobGuid[guid]
        }
        const getRulesByEmployeeGuid = (guid:ApiGuid):ServiceItemRule[]|undefined => {
            return quickLookupByEmployeeGuid[guid]
        }
        const getRulesByGroupGuid = (guid:ApiGuid):ServiceItemRule[]|undefined => {
            return quickLookupByGroupGuid[guid]
        }
        const getRulesByServiceItemGuid = (guid:ApiGuid):ServiceItemRule[]|undefined => {
            return quickLookupByServiceItemGuid[guid]
        }

        return {
            getRulesByJobGuid,
            getRulesByEmployeeGuid,
            getRulesByGroupGuid,
            getRulesByServiceItemGuid
        }
    },[serviceItemRules])


    const { errorString: loadError } = ReactQueryExtractErrorString(VQueryKey.ServiceItemRules, query);
    return {
        isLoading: query.isLoading,
        isFetched: query.isFetched,
        loadError: loadError,
        serviceItemRules,
        deleteRule,
        createRule,
        findRuleIndex,
        ruleEngine,
        lookup
    }      
}


const commonUseQueryOptionsServiceItemRateRules:UseQueryOptions<ServiceItemRateRule[]> = {
    ...ReactQueryCommonUseQueryOptions
}
export function useServiceItemRateRules(props?:{enabled?:boolean})
{
    const options = props ?? {};
    const { enabled=true } = options;
    const { api } = useVeriClockApi();
    const queryClient = useQueryClient();
    const query = useQuery<ServiceItemRateRule[]>(VQueryKey.ServiceItemRateRules, async () => (await api.getServiceItemRateRules()).payload.rules, {
        enabled,
        ...commonUseQueryOptionsServiceItemRateRules
    });

    const findRuleIndex = useCallback( (rule:ServiceItemRateRuleForCreate) => {

        let ruleList = queryClient.getQueryData<ServiceItemRateRule[]>(VQueryKey.ServiceItemRateRules); 
        if(!ruleList) //no list, there for not in the list
            return -1;

        return ruleList.findIndex(r => {
            return r.employee === rule.employee &&
                r.group === rule.group &&
                r.job === rule.job &&
                r.serviceItem === rule.serviceItem
        });
    },[queryClient]);

    const deleteRule = useCallback( async (rule:ServiceItemRateRule) => {
        await api.deleteServiceItemRateRule([rule]);
        
        let ruleList = queryClient.getQueryData<ServiceItemRateRule[]>(VQueryKey.ServiceItemRateRules); 
        if(!ruleList) //wasn't in the list...odd, ignore
            return;

        //rule is identified by the contents - they are collectively unique
        let index = ruleList.findIndex(r => {
            return r.employee === rule.employee &&
                r.group === rule.group &&
                r.job === rule.job &&
                r.serviceItem === rule.serviceItem;
        });
        if(index >= 0) //if found, remove from list and reset the value of the query
        {
            ruleList = [...ruleList];
            ruleList.splice(index, 1);
            queryClient.setQueryData<ServiceItemRateRule[]>(VQueryKey.ServiceItemRateRules, ruleList); 
        }
        
    },[queryClient, api]);

    const createRule = useCallback( async (rule:ServiceItemRateRuleForCreate) => {
        let resp = await api.createServiceItemRateRule(rule);
        let newRule = resp.payload.rule;
        
        let ruleList = queryClient.getQueryData<ServiceItemRateRule[]>(VQueryKey.ServiceItemRateRules); 
        if(!ruleList)
            ruleList = [newRule];
        else
            ruleList = [...ruleList, newRule]; //new array ref

        queryClient.setQueryData<ServiceItemRateRule[]>(VQueryKey.ServiceItemRateRules, ruleList); 
        return newRule;
    },[queryClient,api]);

    const ruleEngine:ServiceItemRateRuleEngineLookupFunction = useMemo( () => {
        return createServiceItemRateRuleEngine(query.data);
    },[query.data]);

    const serviceItemRateRules = query.data;
    const lookup = useMemo( () => {
        const quickLookupByJobGuid:Record<ApiGuid, ServiceItemRateRule[]> = {};
        const quickLookupByEmployeeGuid:Record<ApiGuid, ServiceItemRateRule[]> = {};
        const quickLookupByGroupGuid:Record<ApiGuid, ServiceItemRateRule[]> = {};
        const quickLookupByServiceItemGuid:Record<ApiGuid, ServiceItemRateRule[]> = {};
        
        if(serviceItemRateRules)
        {
            serviceItemRateRules.forEach(rule => {
                if(rule.job != 'any')
                {
                    if(!quickLookupByJobGuid[rule.job])
                        quickLookupByJobGuid[rule.job] = [];
                    quickLookupByJobGuid[rule.job].push(rule);  //add the rule to the job's rule array
                }
                if(rule.employee != 'any')
                {
                    if(!quickLookupByEmployeeGuid[rule.employee])
                        quickLookupByEmployeeGuid[rule.employee] = [];
                    quickLookupByEmployeeGuid[rule.employee].push(rule);  //add the rule to the ee's rule array
                }
                if(rule.group != 'any')
                {
                    if(!quickLookupByGroupGuid[rule.group])
                        quickLookupByGroupGuid[rule.group] = [];
                    quickLookupByGroupGuid[rule.group].push(rule);  //add the rule to the grp's rule array                    
                }
                if(rule.serviceItem != 'any')
                {
                    if(!quickLookupByServiceItemGuid[rule.serviceItem])
                        quickLookupByServiceItemGuid[rule.serviceItem] = [];
                    quickLookupByServiceItemGuid[rule.serviceItem].push(rule);  //add the rule to the si's rule array
                }
            })
        }
        
        const getRulesByJobGuid = (guid:ApiGuid):ServiceItemRateRule[]|undefined => {
            return quickLookupByJobGuid[guid]
        }
        const getRulesByEmployeeGuid = (guid:ApiGuid):ServiceItemRateRule[]|undefined => {
            return quickLookupByEmployeeGuid[guid]
        }
        const getRulesByGroupGuid = (guid:ApiGuid):ServiceItemRateRule[]|undefined => {
            return quickLookupByGroupGuid[guid]
        }
        const getRulesByServiceItemGuid = (guid:ApiGuid):ServiceItemRateRule[]|undefined => {
            return quickLookupByServiceItemGuid[guid]
        }

        return {
            getRulesByJobGuid,
            getRulesByEmployeeGuid,
            getRulesByGroupGuid,
            getRulesByServiceItemGuid
        }
    },[serviceItemRateRules])


    const { errorString: loadError } = ReactQueryExtractErrorString(VQueryKey.ServiceItemRateRules, query);
    return {
        isLoading: query.isLoading,
        isFetched: query.isFetched,
        loadError: loadError,
        serviceItemRateRules,
        deleteRule,
        createRule,
        findRuleIndex,
        ruleEngine,
        lookup
    }      
}

// Not sure if this is the best place for this.  I want to have this helper that grabs all the prereq hooks to run this function.
export function useServiceItemAssignments()
{
    const company = useCompanyLoaded();
    const useAdditionalGroups = company.company.settings.multiGroupEnabled;

    const canEmployeesAccessServiceItemFunction = useCallback( (employeeListToTest:Employee[], ignoreAdminStatus:boolean, allowAdminCanAssign:boolean, serviceItem:ServiceItem) => {
    
        return canEmployeesAccessServiceItem(employeeListToTest, serviceItem, { 
            ignoreAdminStatus: ignoreAdminStatus, 
            allowAdminCanAssign: allowAdminCanAssign, 
            useAdditionalGroups 
        });
    }, [useAdditionalGroups] );

    const canJobsAccessServiceItemFunction = useCallback( (jobListToTest:Job[], allowAdminCanAssign:boolean, serviceItem:ServiceItem) => {
        
        return canJobsAccessServiceItem(jobListToTest, serviceItem, { 
            allowAdminCanAssign: allowAdminCanAssign,
            useAdditionalGroups,
        });
    }, [useAdditionalGroups] );

    return {
        canEmployeesAccessServiceItem: canEmployeesAccessServiceItemFunction,
        canJobsAccessServiceItem: canJobsAccessServiceItemFunction,
    }
}