import { ApiGuid, Employee, Group, Job } from '../../../vericlock_api/src/types';

type EmployeeForAccessControl = Pick<Employee,'guid'|'groupGuid'|'type'|'additionalGroupGuids'>;
type JobForAccessControl = Pick<Job,'guid'|'accessControl'|'assignment'|'adminCanAssign'>;

//check the job's access control list and see if employee, or its group is in it (if so configured)
export function canEmployeeAccessJob(employee:EmployeeForAccessControl, job:JobForAccessControl,options: AccessControlOptions & Partial<AccessControlModulation>):boolean
{
    const { 
        ignoreAdminStatus=false, 
        employeeJobAssignmentApplicability,
        allowAdminCanAssign 
    } = options;

    const checkDirectAssignment = options.checkDirectAssignment ?? (employeeJobAssignmentApplicability === 'individualAndGroup' || employeeJobAssignmentApplicability === 'individualOnly');
    const checkGroupAssignment = options.checkGroupAssignment ?? (employeeJobAssignmentApplicability === 'individualAndGroup' || employeeJobAssignmentApplicability === 'groupOnly');

    const fixedOptions = {
        ...options,
        checkDirectAssignment,
        checkGroupAssignment
    }
    //only consider admin status IF the employee is an admin
    if(!ignoreAdminStatus && employee.type === 'admin')
        return true;

    if(allowAdminCanAssign && job.adminCanAssign)
        return true;

    if(job.accessControl === 'none')
        return true;
    
    //if we fall through, employee is either allowed or denied based on being in the list or not.  Lets first see if we're in the list (group or employee)
    const inList = isEmployeeInJobAccessLists(employee, job, fixedOptions);
    if(job.accessControl === 'allowList')
        return inList; //if in list, they are allowed
    else if(job.accessControl === 'denyList')
        return !inList; //deny if in list
    
    throw new Error('Unknown job.accessControl value: ' + job.accessControl);
}

//true if ALL employees can access job
type AccessControlOptions = {
    ignoreAdminStatus?:boolean  //default false - when true, admin status is ignored for access purposes
    allowAdminCanAssign?: boolean, //default false - when true, when admin can assign is set, the thing is allowed (assumes consumer set appropriately)
    useAdditionalGroups:boolean //when true, additionalGroups come into play for access control checks
    managersCanViewJobsAssignedToTheirManagedGroups?:boolean, 
    managersCanViewJobsAssignedToTheirManagedEmployees?:boolean,
    employeeJobAssignmentApplicability: 'individualAndGroup'|'individualOnly'|'groupOnly'
}
type AccessControlModulation = {
    checkGroupAssignment:boolean, 
    checkDirectAssignment:boolean,
    alsoCheckAlternateGroupAssignmentGuids?:ApiGuid[] //if used, only this set of group guids is used to check group assignments
}
export function canEmployeesAccessJob(employees:EmployeeForAccessControl[], job:JobForAccessControl, options:AccessControlOptions ):boolean
{
    if(options.allowAdminCanAssign && job.adminCanAssign)
        return true;

    if(job.accessControl === 'none')
        return true;


    const checkDirectAssignment = options.employeeJobAssignmentApplicability === 'individualAndGroup' || options.employeeJobAssignmentApplicability === 'individualOnly';
    const checkGroupAssignment = options.employeeJobAssignmentApplicability === 'individualAndGroup' || options.employeeJobAssignmentApplicability === 'groupOnly';    
    
    for(let i=0; i< employees.length; i++)
    {
        if(!canEmployeeAccessJob(employees[i], job, {
            ...options,
            checkDirectAssignment,
            checkGroupAssignment
        }))
            return false;
    } 
    //fall through, all must have access
    return true;
}
function canGroupAccessJob(group:Group, groupEmployeeGuids:ApiGuid[], job:JobForAccessControl, options: AccessControlOptions)
{
    if(options.allowAdminCanAssign && job.adminCanAssign)
        return true;

    if(job.accessControl === 'none')
        return true;
    
    const inList = isGroupGuidInJobAccessLists(group.guid, job);

//cases:
// accessControl is allowedList
//    group is in the list => YES
//    group is NOT in the list => NO

// access control is denyList
//   group is in the list, NO
//   group is not in the list =>  
//      group members individaully in the list, NO (group is blocked)
//      no group members in list => YES (group nor members are not in list, so allowed)
        
    // if(inList)
    // {
        if(job.accessControl === 'allowList')
            return inList; //if in list, they are allowed
        else if(job.accessControl === 'denyList') {
            for(let i=0; i < groupEmployeeGuids.length; i++)
            {
                if(isEmployeeGuidInJobAccessLists(groupEmployeeGuids[i], job))
                    return false; //one of the groups employees IS blocked, so this group cannot access the job
            }
            return !inList; //deny if in list
        }
    // }
    
    throw new Error('Unknown job.accessControl value[g]: ' + job.accessControl);
}

//true if ALL groups can access job
export function canGroupsAccessJob(groups:Group[], groupEmployeeGuidsMap:Record<ApiGuid,ApiGuid[]>, job:JobForAccessControl, options: AccessControlOptions):boolean
{
    if(options.allowAdminCanAssign && job.adminCanAssign)
        return true;

    if(job.accessControl === 'none')
        return true;
    
    for(let i=0; i < groups.length; i++)
    {
        if(!canGroupAccessJob(groups[i], groupEmployeeGuidsMap[groups[i].guid], job, options))
            return false;
    }
    return true; //fall through, all groups must have access
}
//checks if this group is explicitly listed in the job
//if all of a group's employees are in the job as individual employees, this will not care, it required the group itself to be listed
export function canGroupAccessJobs(group:Group, groupEmployeeGuids:ApiGuid[], jobs:JobForAccessControl[], options: AccessControlOptions):boolean
{
    for(let i=0; i < jobs.length; i++)
    {
        if(!canGroupAccessJob(group, groupEmployeeGuids, jobs[i], options))
            return false; //early exit
    }
    //fall through, then true
    return true;
}
//true if employee can see ALL jobs
export function canEmployeeAccessJobs(employee:EmployeeForAccessControl, jobs:JobForAccessControl[], options: AccessControlOptions):boolean
{

    const checkDirectAssignment = options.employeeJobAssignmentApplicability === 'individualAndGroup' || options.employeeJobAssignmentApplicability === 'individualOnly';
    const checkGroupAssignment = options.employeeJobAssignmentApplicability === 'individualAndGroup' || options.employeeJobAssignmentApplicability === 'groupOnly';    

    for(let i=0; i < jobs.length; i++)
    {
        if(!canEmployeeAccessJob(employee, jobs[i], 
            {
                ...options,
                checkDirectAssignment,
                checkGroupAssignment
            }))
            return false; //early exit
    }
    //fall through, then true
    return true;
}

function isGroupGuidInJobAccessLists(groupGuid:ApiGuid, job:JobForAccessControl):boolean
{
    const groupIndex = job.assignment?.groupGuids ? job.assignment.groupGuids.findIndex(g => g === groupGuid) : -1;
    if(groupIndex != -1)
        return true; //early exit, employees group is in the list
    return false;
}
//ignoring the actual state (allow/deny), is the employee in the access list 
function isEmployeeInJobAccessLists(employee:EmployeeForAccessControl, job:JobForAccessControl, options: Pick<AccessControlOptions,'useAdditionalGroups'> & AccessControlModulation):boolean
{
    //default behavior is to allow managers to see jobs assigned to their managed groups and employees
    // can optionally turn those off
    const { 
        // checkGroupAssignment=true,
        // checkDirectAssignment=true,
        checkGroupAssignment,
        checkDirectAssignment,
        alsoCheckAlternateGroupAssignmentGuids=null 
    } = options;

    if(alsoCheckAlternateGroupAssignmentGuids)
    {
        for(let i=0; i < alsoCheckAlternateGroupAssignmentGuids.length; i++)
        {
            if(isGroupGuidInJobAccessLists(alsoCheckAlternateGroupAssignmentGuids[i], job))
                return true;
        }
        // return false; //exit early
    }

    if(checkGroupAssignment)
    {
        if(employee.groupGuid && isGroupGuidInJobAccessLists(employee.groupGuid, job))
            return true;

        //if we are to use additional groups, check them too
        if(options.useAdditionalGroups && employee.additionalGroupGuids && employee.additionalGroupGuids.length > 0)
        {
            for(let i=0; i < employee.additionalGroupGuids.length; i++)
            {
                if(isGroupGuidInJobAccessLists(employee.additionalGroupGuids[i], job))
                    return true;
            }
        }
    }

    if(checkDirectAssignment)
        return isEmployeeGuidInJobAccessLists(employee.guid, job);
    
    return false; //fall through - not in any list/or ignored
}
function isEmployeeGuidInJobAccessLists(employeeGuid:ApiGuid, job:JobForAccessControl):boolean
{
    const employeeIndex = job.assignment?.employeeGuids ? job.assignment.employeeGuids.findIndex(e => e === employeeGuid) : -1;
    if(employeeIndex != -1)
        return true;
    return false;
}

//creates a filter for a Job[].sort call
//todo - maybe not used anymore
export function constrainJobsByAssignmentsFilter(constrainForEmployee:EmployeeForAccessControl, allowJobGuid:ApiGuid|undefined, getByGuid:(jobGuid:ApiGuid) => Job|undefined , options: AccessControlOptions)
{
    const checkDirectAssignment = options.employeeJobAssignmentApplicability === 'individualAndGroup' || options.employeeJobAssignmentApplicability === 'individualOnly';
    const checkGroupAssignment = options.employeeJobAssignmentApplicability === 'individualAndGroup' || options.employeeJobAssignmentApplicability === 'groupOnly';    
    const fixedOptions = {
        ...options,
        checkDirectAssignment,
        checkGroupAssignment
    }
    return (job:Job, index:number, jobList:Job[]) => {

        if(allowJobGuid === job.guid) //if specific job was allowed, return true now
            return true; 

        //todo: pass in getByGuid, and canEmployeeAccessJob will be able to recurse up the parent tree optionally if job.applyParentAccessControl is true
        if(canEmployeeAccessJob(constrainForEmployee, job, fixedOptions))
        {
            return true;
        }
        return false;
    } 
}

//returns true if the manager OR any employee in the list can access the job (is assigned or not blocked)
// all it takes is one to be allowed, then it will be good.
export function constrainJobsByManagerOrManagedEmployees(manager:Employee, managedGroupGuids:ApiGuid[], employeeList: Employee[], options: AccessControlOptions)
{
    return (job:Job) => {
        return canManagerOrManagersEmployeesAccessJob(manager, managedGroupGuids, employeeList, job, options);
    }
}
export function canManagerOrManagersEmployeesAccessJob(manager:Employee, managedGroupGuids:ApiGuid[], employeeList: Employee[], job:Job, options: AccessControlOptions):boolean
{
    const { 
        managersCanViewJobsAssignedToTheirManagedEmployees=true,
        managersCanViewJobsAssignedToTheirManagedGroups=true,
        employeeJobAssignmentApplicability='individualAndGroup'
    } = options;

    const individualAssignmentsApplyToEmployee = employeeJobAssignmentApplicability === 'individualAndGroup' || employeeJobAssignmentApplicability === 'individualOnly';
    const groupAssignmentsApplyToEmployee = employeeJobAssignmentApplicability === 'individualAndGroup' || employeeJobAssignmentApplicability === 'groupOnly';

    // if(managersCanViewJobsAssignedToTheirManagedGroups)
    // {
    //     //are the manager's managed groups in the job's group list?
        
    //     if(canEmployeeAccessJob(manager, job, { 
    //         ...options, 
    //         onlyCheckAlternateGroupAssignmentGuids: managedGroupGuids, //manager gets their direct assignments
    //     })) {
    //         return true;
    //     }
    //     return false;
    // }

    //check the manager first
    if(canEmployeeAccessJob(manager, job, { 
        ...options, 
        alsoCheckAlternateGroupAssignmentGuids: managersCanViewJobsAssignedToTheirManagedGroups  ? managedGroupGuids : undefined,
        checkDirectAssignment: individualAssignmentsApplyToEmployee, //manager always gets their direct assignments
        checkGroupAssignment: groupAssignmentsApplyToEmployee //manager always gets their assignment via the groups they are a part of
    })) {
        return true;
    }
    
    if(managersCanViewJobsAssignedToTheirManagedEmployees)
    {
        for(let i=0; i < employeeList.length; i++)
        {
            if(canEmployeeAccessJob(employeeList[i], job, {
                ...options,
                checkDirectAssignment: individualAssignmentsApplyToEmployee,
                checkGroupAssignment: groupAssignmentsApplyToEmployee
            }))
                return true;            
        }
    }

    return false;
}