export function makeFullName(firstNameFirst:boolean, firstName: string, middleName:string|null|undefined, lastName:string|null|undefined, addCommaAfterLastBeforeFirst?:boolean):string
{   
    if(firstNameFirst)
    {
        let middle = (middleName && middleName.length > 0) ? " " + middleName : "";
        let last = (lastName && lastName.length > 0) ? " " + lastName : "";
        return firstName + middle + last;
    }
    else //last first
    {
        let middle = (middleName && middleName.length > 0) ? " " + middleName : "";
        let last = (lastName && lastName.length > 0) ? lastName + (addCommaAfterLastBeforeFirst ? ", " : " ") : ""; //pad lastName if its there with a space on the end
        return last + firstName + middle;
    }   
}

export function makeEmployeeFullName(firstNameFirst:boolean, e: {  firstName: string, middleName:string|null|undefined, lastName:string|null|undefined }, addComma?:boolean)
{
    return makeFullName(firstNameFirst, e.firstName, e.middleName, e.lastName, addComma);
}

//Dorkulus von Monkeyface => Dorkulus M.
export function makeEmployeeNameAbbreviation(firstName: string, lastName: string)
{
    if(lastName.length > 0 && lastName[0] != '')
        return firstName + ' ' + lastName[0] + '.';
    return firstName;
}