import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { ReactQueryCommonUseQueryOptions, ReactQueryExtractErrorString, VQueryKey } from "../../../sharedReact/src/hooks/queryHookHelpers";
import { ApiGuid, ApiParamsPtoQuery, ApiPtoBalanceResponse, ApiPtoQueryResponse, GetThingListOptions, PtoAccrualAssignmentParams, PtoAccrualRuleForCreate, PtoAccrualRuleForUpdate, PtoBalanceEditRequest, PtoBalanceQuery, PtoRecordCancelRequest, PtoRecordForCreate, PtoRecordForUpdate, PtoRecordSetStatusRequest, PtoRecordStatus, PtoType, PtoTypeForCreate, PtoTypeForUpdate, QueryPtoTypeRuleAssignmentResponse, QueryPtoTypeRulesResponse, ThingStatus } from '../../../vericlock_api/src/types'
import { isDateString } from "../../../lib/src/dateTimeHelpers/commonHelpers";
import { BaseUseThingListProps, ThingListActiveInactiveFetchOption, ThingListDeletedFetchOptions, useThingList, UseThingListProps } from "../../../sharedReact/src/hooks/useThingList";
import { useCallback } from "react";
import { useVeriClockApi } from "../../../sharedReact/src/hooks/ApiProvider";

//simplified hook - for dashboard
export function usePtoDoRecordsExist({
    enabled=true
}:{
    enabled?:boolean
}) 
{
    return usePtoRecords({
        enabled,   
        status: [PtoRecordStatus.Pending],
        limit: 1     
    })
}
export function usePtoRecords(props: { enabled?:boolean } & ApiParamsPtoQuery)
{
    const { api } = useVeriClockApi();
    const { enabled, ...queryParams } = props; 
    const theQueryKey = VQueryKey.PtoRecords;

    if(queryParams.searchPeriod)
    {
        if(queryParams.searchPeriod.start && !isDateString(queryParams.searchPeriod.start))
            throw new Error('searchPeriod.start must be a date string YYYY-MM-DD');
        if(queryParams.searchPeriod.end && !isDateString(queryParams.searchPeriod.end))
            throw new Error('searchPeriod.end must be a date string YYYY-MM-DD');
    }

    //as needed, expose more query params to the hook - initial use case is for dashboard to indicate there are some pto requests
    const commonUseQueryOptionsPtoRecords:UseQueryOptions<ApiPtoQueryResponse> = {
        ...ReactQueryCommonUseQueryOptions
    }
    const query = useQuery<ApiPtoQueryResponse>([theQueryKey, queryParams ], async () => {
        return (await api.getPtoRecords(queryParams)).payload}, {
        enabled,
        ...commonUseQueryOptionsPtoRecords,
    });

    const { errorString: loadError } = ReactQueryExtractErrorString(theQueryKey, query);

    const queryClient = useQueryClient();

    const update = useCallback(async (record:PtoRecordForUpdate) => {
        const updatedRecord = await api.updatePtoRecord(record);
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
        return updatedRecord;        
    },[api, queryClient, theQueryKey]);
    
    const create = useCallback(async (record:PtoRecordForCreate) => {        
        let createdRecord = await api.createPtoRecord(record);
        // TODO: append instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
        return createdRecord;        
    },[api, queryClient, theQueryKey]);

    const approvalErrorConflictText = 'Time events are approved, and approved events are blocked from deleting.  Force the time events to be deleted anyways?';

    const cancel = useCallback(async (params:PtoRecordCancelRequest) => {
        try
        {
            await api.cancelPtoRecord( params );
        }
        catch( error )
        {
            // If clock events are approved, need to confirm and try again.
            if (error.code=="ApprovalConflictError")
            {
                let confirmed = confirm(approvalErrorConflictText);
                if (confirmed)
                {
                    // Call recursively with force delete set.
                    return cancel( { ...params, forceDeleteOfApprovedEvents:true } );
                }
            }
            // If we fall through for any reason, rethrow error
            throw error;
        }
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
    },[api, queryClient, theQueryKey]);

    const setStatus = useCallback(async (params:PtoRecordSetStatusRequest) => {   
        try
        {
            await api.setPtoRecordStatus( params );
        }
        catch( error )
        {
            // If clock events are approved, need to confirm and try again.
            if (error.code=="ApprovalConflictError")
            {
                let confirmed = confirm(approvalErrorConflictText);
                if (confirmed)
                {
                    // Call recursively with force delete set.
                    return setStatus( { ...params, forceDeleteOfApprovedEvents:true } );
                }
            }
            // If we fall through for any reason, rethrow error
            throw error;
        }
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
    },[api, queryClient, theQueryKey]);

    return {
        isLoading: query.isLoading,
        loadError,
        isFetched:query.isFetched,
        records: query.data?.records,
        totalRecordsCount: query.data?.totalRecordsCount,
        update,
        create,
        cancel,
        setStatus,
        _query: query
    }
}

export function usePtoBalances(props: { enabled?:boolean } & PtoBalanceQuery)
{
    const { api } = useVeriClockApi();
    const { enabled, ...queryParams } = props;
    const theQueryKey = VQueryKey.PtoBalances;

    const commonUseQueryOptionsPtoBalances:UseQueryOptions<ApiPtoBalanceResponse> = {
        ...ReactQueryCommonUseQueryOptions
    }
    const query = useQuery<ApiPtoBalanceResponse>([theQueryKey, queryParams ], async () => {
        return (await api.getPtoBalances(queryParams)).payload}, {
        enabled,
        ...commonUseQueryOptionsPtoBalances,
    });

    const { errorString: loadError } = ReactQueryExtractErrorString(theQueryKey, query);

    const queryClient = useQueryClient();

    const update = useCallback(async (ptoTypeGuid:ApiGuid, employeeGuid:ApiGuid, params: PtoBalanceEditRequest) => {
        const updatedBalance = await api.updatePtoBalance(ptoTypeGuid,employeeGuid,params);
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
        return updatedBalance;        
    },[api, queryClient, theQueryKey]);

    const bulkImport = useCallback(async (fileName:string, mimeType:string, fileContents:string) => {
        const formData = new FormData();

        const blob = new Blob([fileContents], {
            type: mimeType,
            // lastModified: Date.now() // added to placate typescript types in react-native 0.74.5
        });
        formData.append('file', blob, fileName);
        const res = await api.importPtoBalances(formData);
        queryClient.invalidateQueries([theQueryKey]);
        return res.payload;
    },[api, queryClient, theQueryKey]);

    const triggerRefresh = useCallback(()=>{
        queryClient.invalidateQueries(theQueryKey)
    },[queryClient, theQueryKey]);

    return {
        isLoading: query.isLoading,
        loadError,
        isFetched:query.isFetched,
        balances: query.data?.balances,
        triggerRefresh,
        update,
        bulkImport,
        _query: query
    }
}

export function usePtoAccrualRules(props: { enabled?:boolean, ptoTypeGuid:ApiGuid })
{
    const { api } = useVeriClockApi();
    const { enabled, ...queryParams } = props;
    const theQueryKey = VQueryKey.PtoRules;

    const commonUseQueryOptionsPtoAccrualRules:UseQueryOptions<QueryPtoTypeRulesResponse> = {
        ...ReactQueryCommonUseQueryOptions
    }
    const query = useQuery<QueryPtoTypeRulesResponse>([theQueryKey, queryParams ], async () => {
        return (await api.getPtoTypeRules(queryParams)).payload}, {
        enabled,
        ...commonUseQueryOptionsPtoAccrualRules,
    });

    const { errorString: loadError } = ReactQueryExtractErrorString(theQueryKey, query);

    const queryClient = useQueryClient();

    

    const addRule = useCallback(async (params:PtoAccrualRuleForCreate) => {
       const result = await api.createPtoAccrualRule(params);
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
        return result;
    },[api, queryClient, theQueryKey]);

    const updateRule = useCallback(async (params:PtoAccrualRuleForUpdate) => {
        const result = await api.updatePtoAccrualRule(params)
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
        return result.payload;
    },[api, queryClient, theQueryKey]);

    const deleteRule = useCallback(async (params:{guid:ApiGuid}) => {
        const result = await api.deletePtoTypeRule({
            guid: params.guid
        });
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
        return result;        
    },[api, queryClient, theQueryKey]);

    return {
        isLoading: query.isLoading,
        loadError,
        isFetched:query.isFetched,
        rules: query.data?.rules,
        addRule,
        updateRule,
        deleteRule,
        _query: query
    }
}

export function usePtoAccrualAssignments(props: { enabled?:boolean, ptoTypeGuid:ApiGuid })
{
    const { api } = useVeriClockApi();
    const { enabled, ...queryParams } = props;
    const theQueryKey = VQueryKey.PtoAccrualAssignments;

    const commonUseQueryOptionsPtoAccrualAssignments:UseQueryOptions<QueryPtoTypeRuleAssignmentResponse> = {
        ...ReactQueryCommonUseQueryOptions
    }
    const query = useQuery<QueryPtoTypeRuleAssignmentResponse>([theQueryKey, queryParams ], async () => {
        return (await api.getPtoTypeRuleAssignments(queryParams)).payload}, {
        enabled,
        ...commonUseQueryOptionsPtoAccrualAssignments,
    });

    const { errorString: loadError } = ReactQueryExtractErrorString(theQueryKey, query);

    const queryClient = useQueryClient();

    const addAssignment = useCallback(async (rules:PtoAccrualAssignmentParams[]) => {
        const result = await api.createPtoTypeRuleAssignment(rules);
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
        return result;        
    },[api, queryClient, theQueryKey]);

    const updateAssignment = useCallback(async (rules:PtoAccrualAssignmentParams[]) => {
        const result = await api.updatePtoTypeRuleAssignment(rules);
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
        return result;        
    },[api, queryClient, theQueryKey]);

    const deleteAssignment = useCallback(async (params:{ptoTypeGuid:ApiGuid, employeeGuid:ApiGuid|null, groupGuid:ApiGuid|null}) => {
        const result = await api.deletePtoTypeRuleAssignment({
            employeeGuid: params.employeeGuid,
            groupGuid: params.groupGuid,
            ptoTypeGuid: params.ptoTypeGuid,
            accrualRuleGuid: null,
        });
        // TODO: update instead of invalidate.
        queryClient.invalidateQueries([theQueryKey]);
        return result;        
    },[api, queryClient, theQueryKey]);

    const triggerRefresh = useCallback(()=>{
        queryClient.invalidateQueries(theQueryKey)
    },[queryClient, theQueryKey]);

    return {
        isLoading: query.isLoading,
        loadError,
        isFetched:query.isFetched,
        assignments: query.data?.assignments,
        addAssignment,
        updateAssignment,
        deleteAssignment,
        triggerRefresh,
        _query: query
    }
}

const commonUseQueryOptionsPtoTypes:UseQueryOptions<PtoType[]> = {
    ...ReactQueryCommonUseQueryOptions
}

const activeInactiveQueryKey:UseThingListProps<PtoType>['activeInactiveQueryKey'] = [VQueryKey.PtoTypes, ThingListActiveInactiveFetchOption, false];
const deletedQueryKey:UseThingListProps<PtoType>['deletedQueryKey'] = [VQueryKey.PtoTypes, ThingListDeletedFetchOptions,false];

export function usePtoTypes(props:BaseUseThingListProps)
{
    const { api } = useVeriClockApi();
    const fetchListCallback = useCallback(async (options:GetThingListOptions) => {
        return (await api.getPtoTypes(options)).payload.types
    },[api]);

    const ptoTypeUseThingListResp = useThingList({
        ...props,
        fetchListCallback,
        commonUseQueryOptions: commonUseQueryOptionsPtoTypes,
        activeInactiveQueryKey: activeInactiveQueryKey,
        deletedQueryKey: deletedQueryKey,
        type: 'ptoType'  
    });
    const { updateCache, removeFromCache, getByGuid, things, ...restUseThingsResp } = ptoTypeUseThingListResp; //.updateCache;

    const addPtoType = useCallback(async (ptoType:PtoTypeForCreate) => {
        const result = await api.addPtoType(ptoType);
        updateCache([result.payload],{})
        return result;        
    },[api, updateCache]);

    const updatePtoType = useCallback(async (ptoType:PtoTypeForUpdate, old:PtoType) => {
        const result = await api.updatePtoType(ptoType);
        updateCache([result.payload],{ [result.payload.guid]: old })
        return result;        
    },[api, updateCache]);

    const changePtoTypeStatus = useCallback(async (status:ThingStatus, old:PtoType) => {
        const result = await api.updatePtoType({
            guid:old.guid,
            status: status,
        });
        updateCache([result.payload],{ [result.payload.guid]: old })
        return result;        
    },[api, updateCache]);

    return {
        ...restUseThingsResp,
        types: things,
        getByGuid,
        addPtoType,
        updatePtoType,
        changePtoTypeStatus,
    }
}