// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemLabel
{
    margin-right: 2px;
    display:inline-block;
}
`, "",{"version":3,"sources":["webpack://./webApp/src/ui/components/core/VItemLabel/ItemLabel.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".ItemLabel\n{\n    margin-right: 2px;\n    display:inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
