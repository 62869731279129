import { useCallback, useMemo } from "react";
import { getEmployeeGroupGuidsFromEmployee, isGroupGuidListInEmployeeGroupGuidList } from "../../../lib/src/helpers/employeeHelpers";
import { combineIsFetched, combineIsLoading, combineLoadErrors } from "../../../sharedReact/src/hooks/queryHookHelpers";
import { ApiGuid, Employee, Group } from "../../../vericlock_api/src/types";
import { useCompany } from "./companyHooks";
import { useGroupList } from "./GroupHooks";

// export function useManagers(propsOrig?:{
//     enabled?:boolean
// })
export function useManagers({
    enabled=true,
    teamView=false,                 //request a teamview version - todo: eval this...should always work perhaps
}:{
    enabled?:boolean,
    teamView?:boolean
}={})

{
    const groups = useGroupList({ statusToFetch: ['active','inactive'], enabled, teamView }); //managers are only managers if they manage an active group...? maybe inactive too - though, not going to be much to do...deleted, def not
    const company = useCompany();
    const multiGroupEnabled = company.company?.settings.multiGroupEnabled;
    const isLoading = combineIsLoading([groups]);
    const isFetched = combineIsFetched([groups]);
    const loadError = combineLoadErrors([groups]);

    const loadErrorMsg = loadError?.join(',') ?? null;
    const employeeToManagedGroupsMap = useMemo(() => {
        let map:Record<ApiGuid,Group[]> = {};
        if(groups.groups){
            groups.groups.forEach(group => {
                if(group.managingEmployeeList)
                {
                    group.managingEmployeeList.forEach(guid => {
                        if(!map[guid])
                            map[guid] = [];
                        map[guid].push(group);
                    })
                }
            })
        }
        return map;
    },[groups.groups])

    const isManager = useCallback( (employee:Employee) => {
        const groups = employeeToManagedGroupsMap[employee.guid];
        return (groups?.length > 0)
    },[employeeToManagedGroupsMap]);

    const getManagedGroups = useCallback((employee:Employee) => {
        const groups = employeeToManagedGroupsMap[employee.guid];
        return (groups?.length > 0) ? groups : [];
    },[employeeToManagedGroupsMap]);

    const isEmployeeManagedByManager = useCallback( (employee:Employee, manager:Employee)  => {
        const managerGroups = getManagedGroups(manager);
        const employeeGroupGuids = getEmployeeGroupGuidsFromEmployee(!!multiGroupEnabled, employee);
        if(isGroupGuidListInEmployeeGroupGuidList(employeeGroupGuids, managerGroups.map(g => g.guid)))
            return true;
        return false;
    },[getManagedGroups, multiGroupEnabled]);
    
    const isEmployeeManagerOfGroup = useCallback( (employee:Employee, groupGuid:ApiGuid ) => {
        let eesGroups = getManagedGroups(employee);
        return eesGroups.findIndex((group) => group.guid === groupGuid) >= 0;
    },[getManagedGroups]);

    return {
        isLoading,
        isFetched,
        loadError: loadErrorMsg,
        isManager,
        getManagedGroups,
        isEmployeeManagedByManager,
        isEmployeeManagerOfGroup
    }
}