import React, { useCallback, useMemo } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { ApiGuid, Employee, Job, JobBudgetInfo, JobBudgetRange, JobForCreate, JobForCreateOrUpdate, JobForUpdate, JobQueryOptions, JobRule, JobRuleEngineLookupFunction, JobRuleForCreate } from "../../../vericlock_api/src/types";
import { ReactQueryExtractErrorString, VQueryKey, ReactQueryCommonUseQueryOptions } from "../../../sharedReact/src/hooks/queryHookHelpers";
import { GetThingListOptions } from '../../../vericlock_api/src/types/Thing'
import { BaseUseThingListProps, useThingList, UseThingListProps } from "../../../sharedReact/src/hooks/useThingList";
import { ThingListActiveInactiveFetchOption, ThingListDeletedFetchOptions } from "../../../sharedReact/src/hooks/useThingList";
import { invalidateQueriesFromJobUpdate } from "./ContactHooks";
import { invalidateAllIntegrationMapQueriesOnJobCreateUpdate } from "./AccountingIntegrationHooks";
import { createJobRuleEngine } from "../../../lib/src/RuleEngines/JobRulesEngine";
import { useVeriClockApi } from "../../../sharedReact/src/hooks/ApiProvider";
import { canEmployeesAccessJob } from "../../../lib/src/RuleEngines/JobAccessControl";
import { useCompanyLoaded } from "./companyHooks";

//we want to fetch the deleted list, only if we didn't find the job in the first place
export function useJob(jobGuid:ApiGuid|null|undefined)
{
    // console.warn('note - this is loading all active/inactive/deleted THEN deleted...odd');
    const jMain = useJobList({
        statusToFetch: ['active','inactive'], 
        enabled: jobGuid != null  
    });

    //check main list first
    let job = jMain.jobs.find(j => j.guid === jobGuid);    
    //flag to load deleted items, if job isn't found AFTER the list is queried
    const lookInTheTrash = jobGuid ? (jMain.isFetched && !job) : false;

    //only load/check the deleted jobs, if the activeInactive loaded and it wasn't in there.
    const jDel = useJobList({
        statusToFetch: ['deleted'], 
        enabled: lookInTheTrash  
    });

    if(lookInTheTrash)
    {
        job = jDel.jobs.find(j => j.guid === jobGuid);        
    }

    //combined isLoading
    const isLoading = jobGuid ? (jMain.isLoading || lookInTheTrash && jDel.isLoading) : false
    //combined loadError
    let loadError = null;
    if(jobGuid)
    {
        const errs:string[] = [];
        if(jMain.loadError)
            errs.push(jMain.loadError)
        if(lookInTheTrash && jDel.loadError)
            errs.push(jDel.loadError);
        if(errs.length)
            loadError = errs.join(','); 
    }

    return {
        job,
        isLoading,
        isFetched:jMain.isFetched,
        loadError,
        update:jMain.update,
        create:jMain.create,
    }
}

//fetches a job's current budget info
export function useJobBudgetInfo(jobGuid:ApiGuid, jobBudgetRange: JobBudgetRange, load:boolean) //load => false, lets use trigger the load later
{
    const { api } = useVeriClockApi();
    const commonUseQueryOptionsJobBudgets:UseQueryOptions<JobBudgetInfo> = {
        ...ReactQueryCommonUseQueryOptions
    }
    const query = useQuery<JobBudgetInfo>([VQueryKey.JobBudgetInfoProgress, jobGuid, jobBudgetRange], async () => (await api.getJobBudget(jobGuid, jobBudgetRange)).payload, {
        enabled: load,
        ...commonUseQueryOptionsJobBudgets,
    });
    const errInfo = ReactQueryExtractErrorString(VQueryKey.JobBudgetInfoProgress, query);
    return {
        jobBudgetInfo:query.data,
        isLoading:query.isLoading,
        error: errInfo.errorString
    }
}

const commonUseQueryOptionsJob:UseQueryOptions<Job[]> = {
    ...ReactQueryCommonUseQueryOptions,
}
//NOTE by making these constants, the queries are safely cached - RQ treats array order as a unique key, so 'a','b' != 'b','a'

export type UseJobListProps = BaseUseThingListProps;
type PrecomputedHelp<D> =
{
    guidToChildren: Record<ApiGuid, {
        children: D[]        
    }>,
    codeToJob: Record<number, D>
}
const activeInactiveQueryKey:UseThingListProps<Job>['activeInactiveQueryKey'] = [VQueryKey.JobList, ThingListActiveInactiveFetchOption, false];
const deletedQueryKey:UseThingListProps<Job>['deletedQueryKey'] = [VQueryKey.JobList, ThingListDeletedFetchOptions, false];
const activeInactiveQueryKeyTeamView:UseThingListProps<Job>['activeInactiveQueryKey'] = [VQueryKey.JobList, ThingListActiveInactiveFetchOption, true];
const deletedQueryKeyTeamView:UseThingListProps<Job>['deletedQueryKey'] = [VQueryKey.JobList, ThingListDeletedFetchOptions, true];
export function useJobList(props:UseJobListProps)
{
    const { api } = useVeriClockApi();

    const queryClient = useQueryClient();
    const fetchListCallback = useCallback(async (options:GetThingListOptions) => {
        const searchOptions:JobQueryOptions = 
        {
            status: options.status,
            includeAccessControlList: true,      //since its getting cached, get the best version? - could make this dynamic and fetch if not fetched, etc...but this is simpler for now   
            teamView: options.teamView,
            includeAdminNotes: true,             // if non-admin fetches jobs, this setting will be ignored.
        }
    
        return (await api.queryJobs(searchOptions)).payload;
    },[api]);

    const jobUseThingListResp = useThingList({
        ...props,
        fetchListCallback,
        commonUseQueryOptions: commonUseQueryOptionsJob,
        //use static/constant ref - otherwise everything re-renders...
        activeInactiveQueryKey : props.teamView ? activeInactiveQueryKeyTeamView : activeInactiveQueryKey,
        deletedQueryKey: props.teamView ? deletedQueryKeyTeamView : deletedQueryKey,
        type:'job'
    });
    const { updateCache, getByGuid, things, ...restUseThingsResp } = jobUseThingListResp; //.updateCache;

    const createUpdateList = useCallback(async (jobs:JobForCreateOrUpdate[], oldJobsMap:Record<ApiGuid, Job>) => {
        const r = await api.createUpdateJobList({ jobs });
        updateCache(r.payload, oldJobsMap);
        if(jobs.filter(j => j.thirdPartyContact).length > 0)
            invalidateQueriesFromJobUpdate(queryClient);
        return r.payload;
    },[api,updateCache,queryClient]);

    const preComputedHelp = useMemo(() => {
        let data:PrecomputedHelp<Job> = {
            guidToChildren: {},
            codeToJob: {}
        }
        things.forEach(job => {
            if(job.parentGuid)
            {
                if(!data.guidToChildren[job.parentGuid]) { //init the data structure if we haven't seen this parent yet
                    data.guidToChildren[job.parentGuid] = {
                        children:[]
                    }
                }
                data.guidToChildren[job.parentGuid].children.push(job); //add this job to the children jobs list 
                //future: additioanl helpers, like a map, or a map/list of all descendants from this job..etc.
            }
            data.codeToJob[job.code] = job;
        });
        return data;
    },[things]);

    const getChildren = useCallback( (job:Job) => {
        let childrenStructure = preComputedHelp.guidToChildren[job.guid]
        if(childrenStructure)
            return childrenStructure.children;
        return []; //no children if not found (or they could be deleted)
    },[preComputedHelp]);
    const getByCode = useCallback( (code:number):Job|undefined => {
        return preComputedHelp.codeToJob[code];
    },[preComputedHelp]);
    const getDescendants = useCallback( (job:Job) => {
        let children = getChildren(job);
        let descendants:Job[] = []; //starting point
        children.forEach(child => {
            const childDescendants = getDescendants(child);
            descendants.push(child); //push child, then its descendants
            childDescendants.forEach(d => descendants.push(d)); //add child descendants to the list
        });
        return descendants;
    },[getChildren]);

    const updateChildrenToNewStatusInCache = useCallback( (job:Job) => {
        const children = getChildren(job);
        children.forEach(child => {
            
            if(child.status != job.status && //only alter children (and descendants) if children are more active than new job status
                ((child.status === 'active' && (job.status === 'inactive' || job.status === 'deleted'))
                || (child.status === 'inactive' && job.status === 'deleted')))
            {
                updateCache([{...child, status: job.status }], { [child.guid]: child });
                //also do this jobs children
                updateChildrenToNewStatusInCache(child);
            }             
        });
    },[getChildren, updateCache]);

    const update = useCallback(async (item:JobForUpdate, oldItem:Job) => {
        const updatedItem = (await api.updateJob(item)).payload;
        updateCache([updatedItem], { [oldItem.guid]: oldItem });
        //handle decendents
        if(oldItem.status != updatedItem.status &&
            ((oldItem.status === 'active' && (updatedItem.status === 'inactive' || updatedItem.status === 'deleted'))
            || (oldItem.status === 'inactive' && updatedItem.status === 'deleted')))
        {
            updateChildrenToNewStatusInCache(updatedItem);
        }

        return updatedItem;        
    },[api, updateCache, updateChildrenToNewStatusInCache]);

    const create = useCallback(async (job:JobForCreate) => {        
        let createdJob = (await api.createJob(job)).payload;
        updateCache([createdJob], {}); //empty map, signals JUST a create
        if(job.thirdPartyContact)
            invalidateQueriesFromJobUpdate(queryClient)
        invalidateAllIntegrationMapQueriesOnJobCreateUpdate(queryClient); 
        return createdJob;        
    },[api, updateCache,queryClient]);

    return {
        ...restUseThingsResp,
        jobs: things,
        createUpdateList,
        update,
        create,
        getByGuid,
        getChildren,
        getDescendants,
        getByCode
    }
}

const commonUseQueryOptionsJobRules:UseQueryOptions<JobRule[]> = {
    ...ReactQueryCommonUseQueryOptions,
    // refetchOnMount:true
    // staleTime: 1000*5
}
export function useJobRules(props?:{enabled?:boolean})
{
    const options = props ?? {};
    const { enabled=true } = options;
    const { api } = useVeriClockApi();    
    
    const queryClient = useQueryClient();
    const query = useQuery<JobRule[]>(VQueryKey.JobRules, async () => (await api.getJobRules()).payload, {
        enabled,
        ...commonUseQueryOptionsJobRules
    });

    const findRuleIndex = useCallback( (rule:JobRuleForCreate) => {

        let ruleList = queryClient.getQueryData<JobRule[]>(VQueryKey.JobRules); 
        if(!ruleList) //no list, there for not in the list
            return -1;

        return ruleList.findIndex(r => {
            return r.callerID === rule.callerID &&
                r.clockMethod === rule.clockMethod &&
                r.employee === rule.employee &&
                r.group === rule.group;
        });
    },[queryClient]);

    const deleteRule = useCallback( async (rule:JobRule) => {
        await api.deleteJobRule(rule);
        
        let ruleList = queryClient.getQueryData<JobRule[]>(VQueryKey.JobRules); 
        if(!ruleList) //wasn't in the list...odd, ignore
            return;

        //rule is identified by the contents - they are collectively unique
        let index = ruleList.findIndex(r => {
            return r.callerID === rule.callerID &&
                r.clockMethod === rule.clockMethod &&
                r.employee === rule.employee &&
                r.group === rule.group &&
                r.job === rule.job;
        });
        if(index >= 0) //if found, remove from list and reset the value of the query
        {
            ruleList = [...ruleList];
            ruleList.splice(index, 1);
            queryClient.setQueryData<JobRule[]>(VQueryKey.JobRules, ruleList); 
        }
        
    },[queryClient, api]);

    const createRule = useCallback( async (rule:JobRuleForCreate) => {
        let resp = await api.createJobRule(rule);
        let newRule = resp.payload
        
        let ruleList = queryClient.getQueryData<JobRule[]>(VQueryKey.JobRules); 
        if(!ruleList)
            ruleList = [newRule];
        else
            ruleList = [...ruleList, newRule]; //new array ref

        queryClient.setQueryData<JobRule[]>(VQueryKey.JobRules, ruleList); 
        return newRule;
    },[queryClient,api]);

    const ruleEngine:JobRuleEngineLookupFunction = useMemo( () => {
       return createJobRuleEngine(query.data);
    },[query.data]);

    const jobRules = query.data;
    const lookup = React.useMemo( () => {
        const quickLookupByJobGuid:Record<ApiGuid, JobRule[]> = {};
        const quickLookupByEmployeeGuid:Record<ApiGuid, JobRule[]> = {};
        const quickLookupByGroupGuid:Record<ApiGuid, JobRule[]> = {};

        if(jobRules)
        {
            jobRules.forEach(rule => {
                //will create entries for 'required' | 'optonal' | 'none' - could filter if we cared
                if(!quickLookupByJobGuid[rule.job])
                    quickLookupByJobGuid[rule.job] = [];
                quickLookupByJobGuid[rule.job].push(rule); 

                if(!quickLookupByEmployeeGuid[rule.employee])
                    quickLookupByEmployeeGuid[rule.employee] = [];
                quickLookupByEmployeeGuid[rule.employee].push(rule);
                
                if(!quickLookupByGroupGuid[rule.group])
                    quickLookupByGroupGuid[rule.group] = [];
                quickLookupByGroupGuid[rule.group].push(rule);  
            })
        }
        
        const getRulesByJobGuid = (jobGuid:ApiGuid):JobRule[]|undefined => {
            return quickLookupByJobGuid[jobGuid]
        }
        const getRulesByEmployeeGuid = (guid:ApiGuid):JobRule[]|undefined => {
            return quickLookupByEmployeeGuid[guid]
        }
        const getRulesByGroupGuid = (guid:ApiGuid):JobRule[]|undefined => {
            return quickLookupByGroupGuid[guid]
        }
        return {
            getRulesByJobGuid,
            getRulesByGroupGuid,
            getRulesByEmployeeGuid
        }
    },[jobRules])
    const { errorString: loadError } = ReactQueryExtractErrorString(VQueryKey.JobRules, query);
    return {
        isLoading: query.isLoading,
        isFetched: query.isFetched,
        loadError: loadError,
        jobRules,
        deleteRule,
        createRule,
        findRuleIndex,
        ruleEngine,
        lookup
    }      
}

// Not sure if this is the best place for this.  I want to have this helper that grabs all the prereq hooks to run this function.
export function useJobAssignments()
{
    const company = useCompanyLoaded();
    const useAdditionalGroups = company.company.settings.multiGroupEnabled;
    const permissions = company.company.settings.permissions; 
    const managersCanViewJobsAssignedToTheirManagedGroups = permissions.managersCanViewJobsAssignedToTheirManagedGroups;
    const managersCanViewJobsAssignedToTheirManagedEmployees = permissions.managersCanViewJobsAssignedToTheirManagedEmployees;
    const employeeJobAssignmentApplicability = permissions.employeeJobAssignmentApplicability ?? 'individualAndGroup';

    const canEmployeesAccessJobFunction = useCallback( (employeeListToTest:Employee[], ignoreAdminStatus:boolean, allowAdminCanAssign:boolean, job:Job) => {
    
        return canEmployeesAccessJob(employeeListToTest, job, { 
            ignoreAdminStatus: ignoreAdminStatus, allowAdminCanAssign: allowAdminCanAssign, 
            useAdditionalGroups,
            managersCanViewJobsAssignedToTheirManagedGroups,    
            managersCanViewJobsAssignedToTheirManagedEmployees, 
            employeeJobAssignmentApplicability
        });
    }, [employeeJobAssignmentApplicability, managersCanViewJobsAssignedToTheirManagedEmployees, managersCanViewJobsAssignedToTheirManagedGroups, useAdditionalGroups] );

    return {
        canEmployeesAccessJob: canEmployeesAccessJobFunction,
    }
}