import { ApiGuid } from "../../../vericlock_api/src/types/ApiGuid";
import { Employee } from "../../../vericlock_api/src/types/Employee";

export function getEmployeeGroupGuidsFromEmployee(useAdditionalGroups:boolean, employee:Employee):ApiGuid[]
{
    return getEmployeeGroupGuids(useAdditionalGroups, employee.groupGuid, employee.additionalGroupGuids);
}
export function doEmployeesHaveGroupInCommon(useAdditionalGroups:boolean, employeeA:Employee, employeeB:Employee):boolean
{
    const groupsA = getEmployeeGroupGuidsFromEmployee(useAdditionalGroups, employeeA);
    const groupsB = getEmployeeGroupGuidsFromEmployee(useAdditionalGroups, employeeB);
    return isGroupGuidListInEmployeeGroupGuidList(groupsA, groupsB);
}
export function getEmployeeGroupGuids(useAdditionalGroups:boolean, employeeGroupGuid:ApiGuid|null, employeeAdditionalGroupGuids:ApiGuid[]):ApiGuid[]
{
    let employeeGroupGuids = employeeGroupGuid ? [employeeGroupGuid]:[]
    
    if(useAdditionalGroups)
    {
        if(employeeAdditionalGroupGuids.length)
        {
            // if(!employeeGroupGuids)
            //     employeeGroupGuids = [];
            employeeGroupGuids.push(...employeeAdditionalGroupGuids);
        }
    }
    return employeeGroupGuids;
}
export function isEmployeeInGroupList(useAdditionalGroups:boolean, employee:Employee, groupGuids:ApiGuid[]):boolean
{
    return isGroupGuidListInEmployeeGroupGuidList(getEmployeeGroupGuidsFromEmployee(useAdditionalGroups, employee), groupGuids);
}
export function isEmployeeInGroup(useAdditionalGroups:boolean, employee:Employee, groupGuid:ApiGuid):boolean
{
    return isGroupGuidListInEmployeeGroupGuidList(getEmployeeGroupGuidsFromEmployee(useAdditionalGroups, employee), [groupGuid]);
}

export function isGroupGuidListInEmployeeGroupGuidList(needles:ApiGuid[], haystack:ApiGuid[]):boolean
{
    for(let needle of needles)
    {
        if(haystack.includes(needle))
            return true;       
    }
    return false;
}

export function isEmployeeInEitherFilter(yesIfFiltersAreEmptyOrUndefined:boolean, employeeGuid:ApiGuid, employeeGroupGuids:ApiGuid[], employeeGuids?:ApiGuid[], groupGuids?:ApiGuid[]):boolean
{
    const effectiveEmployeeGuids = employeeGuids ? employeeGuids : [];
    const effectiveGroupGuids = groupGuids ? groupGuids : [];

    if(effectiveEmployeeGuids.length === 0 && effectiveGroupGuids.length === 0)
    {
        return yesIfFiltersAreEmptyOrUndefined;
    }          
    else if(effectiveEmployeeGuids.includes(employeeGuid))
    {
        return true;
    }
    else if(isGroupGuidListInEmployeeGroupGuidList(employeeGroupGuids,effectiveGroupGuids))
    {
        return true;
    }
    return false;
}

export function areThereDuplicateGroupGuids(groupGuid:ApiGuid|null, additionalGroupGuids:ApiGuid[]):boolean
{
    const guidSeen:Record<ApiGuid,true> = {};
    if(groupGuid)
        guidSeen[groupGuid] = true;

    for(let guid of additionalGroupGuids)
    {
        if(guidSeen[guid])
            return true;
        guidSeen[guid] = true;
    }
    return false;
}

export function getEmployeesInGroups(employees:Employee[], groupGuids:ApiGuid[], useAdditionalGroups:boolean)
{
    //filter employee list by whether they have any of the group guids
    return employees.filter(e => isGroupGuidListInEmployeeGroupGuidList( groupGuids, getEmployeeGroupGuidsFromEmployee(useAdditionalGroups, e)));
}

export function deDupeGuidList(guids:ApiGuid[]):ApiGuid[]
{
    const seen:Record<ApiGuid,true> = {};
    return guids.filter(g => {
        if(seen[g])
            return false;
        seen[g] = true;
        return true;
    });
}