import { useMemo, useState, useEffect, useCallback } from 'react'

import { ReactQueryCommonUseQueryOptions, ReactQueryExtractErrorString, VQueryKey } from "../../../sharedReact/src/hooks/queryHookHelpers"
import { ApiGuid, Employee, EmployeeForCreate, EmployeeForCreateOrUpdate, EmployeeForUpdate, employeeIsEmployeeForUpdate, QueryCoreDataResponse, VisualCustomizationsCombined } from '../../../vericlock_api/src/types'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { BaseUseThingListProps, ThingListActiveInactiveFetchOption, ThingListDeletedFetchOptions, useThingList, UseThingListProps } from '../../../sharedReact/src/hooks/useThingList'
import { useVeriClockApi } from '../../../sharedReact/src/hooks/ApiProviderCommon'
import { useV } from './V'
import { useCompany } from './companyHooks'
import { EmployeeDeleteWarningsCheckResponse } from '../../../vericlock_api/src/types/Employee'
import { GetThingListOptions } from '../../../vericlock_api/src/types/Thing'

export function useEmployeeNextId(id:string|null)
{
    let [isLoading, setIsLoading] = useState<boolean>(false); //defaults loading for now
    let [suggestedEmployeeId, setSuggestedEmployeeId] = useState<string|null>(null);
    const { api } = useVeriClockApi();

    useEffect(() => {
        if(id !== null)
        {
            setIsLoading(true);
            const checkEmployeeIdAvailable = async () => {
                let res = await api.checkEmployeeIdAvailable({id:id});
                setSuggestedEmployeeId(res.payload);
                setIsLoading(false);
            }
            checkEmployeeIdAvailable();
        }
    }, [api,id]);

    return {suggestedEmployeeId,isLoading};
}

// WARNING: the cached version of the current employee does not come from node, it's the PHP object
// which has some differences.  When in doubt, skip the cache and fetch directly from node with
// useCache = false
export function useEmployee(employeeGuid:ApiGuid|null|undefined)
{
    //doesn't strictly include self unless manager/admin or other access reason
    return useEmployeeImpl(employeeGuid, false);
}
export function useEmployeeIncludeSelf(employeeGuid:ApiGuid|null|undefined)
{
    //will include self if the employee is the user, regardless of in-list status
    return useEmployeeImpl(employeeGuid, true);
}

export function useEmployeeImpl(employeeGuid:ApiGuid|null|undefined, includeSelf:boolean)
{
    // console.warn('note - this is loading all active/inactive/deleted THEN deleted...odd');
    const self = useV().getUser();

    const eMain = useEmployeeList({
        statusToFetch: ['active','inactive'], 
        enabled: self.guid !== employeeGuid && employeeGuid != null, //disable if self is the employee
    });

    let employee = self.guid === employeeGuid ? self
        : employeeGuid ? eMain.getByGuid(employeeGuid) : null;
    //check main list first
    // let employee = eMain.employees.find(e => e.guid === employeeGuid);    
    //flag to load deleted items, if job isn't found AFTER the list is queried
    const lookInTheTrash = employeeGuid ? (eMain.isFetched && !employee) : false;

    //only load/check the deleted jobs, if the activeInactive loaded and it wasn't in there.
    const eDel = useEmployeeList({
        statusToFetch: ['deleted'], 
        enabled: lookInTheTrash  
    });

    if(lookInTheTrash && employeeGuid)
    {
        // employee = eDel.employees.find(e => e.guid === employeeGuid);        
        employee = eDel.getByGuid(employeeGuid)
    }

    //combined isLoading
    const isLoading = employeeGuid ? (eMain.isLoading || lookInTheTrash && eDel.isLoading) : false;
    //combined loadError
    let loadError = null;
    if(employeeGuid)
    {
        const errs:string[] = [];
        if(eMain.loadError)
            errs.push(eMain.loadError)
        if(lookInTheTrash && eDel.loadError)
            errs.push(eDel.loadError);
        if(errs.length)
            loadError = errs.join(','); 
    }

    return {
        employee,
        isLoading,
        isFetched:eMain.isFetched,
        loadError,
        update:eMain.update,
        create:eMain.create
    }
}

const commonUseQueryOptionsEmployee:UseQueryOptions<Employee[]> = {
    ...ReactQueryCommonUseQueryOptions
}
//NOTE by making these constants, the queries are safely cached - RQ treats array order as a unique key, so 'a','b' != 'b','a'

export type UseEmployeeListProps = BaseUseThingListProps;

const activeInactiveQueryKey:UseThingListProps<Employee>['activeInactiveQueryKey'] = [VQueryKey.EmployeeList, ThingListActiveInactiveFetchOption, false];
const activeInactiveQueryKeyTeamView:UseThingListProps<Employee>['activeInactiveQueryKey'] = [VQueryKey.EmployeeList, ThingListActiveInactiveFetchOption, true];
const deletedQueryKey:UseThingListProps<Employee>['deletedQueryKey'] = [VQueryKey.EmployeeList, ThingListDeletedFetchOptions,false];
const deletedQueryKeyTeamView:UseThingListProps<Employee>['deletedQueryKey'] = [VQueryKey.EmployeeList, ThingListDeletedFetchOptions, true];

export const employeeCleanupQueryKeys = [activeInactiveQueryKey,activeInactiveQueryKeyTeamView,deletedQueryKey,deletedQueryKeyTeamView];

export function useEmployeeList(props:UseEmployeeListProps)
{
    const { api } = useVeriClockApi();
    const { company } = useCompany();    
    const useAdditionalGroups = company?.settings.multiGroupEnabled ?? false; //todo - useCompanyLoaded and avoid this undefined business...companyLoaded soon to be standard 
    
    const queryClient = useQueryClient();
    const fetchListCallback = useCallback(async (options:GetThingListOptions) => {
        const resp = await api.queryEmployees({includeAdminNotes: true, ...options}); //admin notes always included... prob incorrect in the hook, but fine for the moment

        // This should be done in SQL
        resp.payload.sort((a, b) => {
            const lhs = a.phoneID;
            const rhs = b.phoneID;
            return lhs < rhs ? -1 : rhs < lhs ? 1 : 0;
        });

        return resp.payload;
    },[api]);
    
    const employeeUseThingListResp = useThingList({
        ...props,
        fetchListCallback,
        commonUseQueryOptions: commonUseQueryOptionsEmployee,
        activeInactiveQueryKey: props.teamView ? activeInactiveQueryKeyTeamView : activeInactiveQueryKey,
        deletedQueryKey: props.teamView ? deletedQueryKeyTeamView : deletedQueryKey,
        type:'employee'  
    });
    const { updateCache:thingListUpdateCache, getByGuid, things, ...restUseThingsResp } = employeeUseThingListResp; //.updateCache;

    const updateCache = useCallback((newEmployees:Employee[], oldEmployeesMap:Record<ApiGuid, Employee>) => {
        thingListUpdateCache(newEmployees, oldEmployeesMap);

        //update core data if its got the employee as the current user
        //no need to worry about going to deleted - not a thing for the core data
        for(let ee of newEmployees)
        {                
            const blobs = queryClient.getQueriesData<QueryCoreDataResponse>(VQueryKey.CoreData);
            blobs.forEach(([qKey, data]) => {
                if(data.user.guid === ee.guid)
                {
                    //employee is in the query data, replace it and set it
                    const newData = {
                        ...data, 
                        user: {
                            ...data.user,
                            settings: {
                                ...data.user.settings,
                                visualCustomizations: ee.settings.visualCustomizations
                            }
                        }
                    }
                    queryClient.setQueryData(qKey, newData);
                }
            });
        }
    },[thingListUpdateCache, queryClient]);

    const bustManagerCache = useCallback((employees:EmployeeForCreateOrUpdate[]) => {
        let needsBusting = false;
        for(let ee of employees)
        {  
            if(ee.managedGroupGuids)
            {
                needsBusting = true;
                break;
            }
        }
        if(needsBusting)
        {
            //invalidae query for managers
            queryClient.invalidateQueries([VQueryKey.CoreData]);
            queryClient.invalidateQueries(VQueryKey.GroupList); //all group queries - manager fields
        }
    },[queryClient]);
    const createUpdateList = useCallback(async (employees:EmployeeForCreateOrUpdate[]) => { //, oldEmployeesMap:Record<ApiGuid, Employee>) => {
        const r = await api.createUpdateEmployeeList(employees);
        const oldMap = employees.reduce((prev,cur) => {
            if(employeeIsEmployeeForUpdate(cur))
            {
                const oldEe = getByGuid(cur.guid);
                if(oldEe) {
                    prev[oldEe.guid] = oldEe;
                }
            }
            return prev;
        },{} as Record<ApiGuid, Employee>);
        updateCache(r.payload, oldMap);
        queryClient.invalidateQueries([VQueryKey.PayrollInfoEmployees]);
        bustManagerCache(employees);
        return r.payload;
    },[api,updateCache, getByGuid, queryClient, bustManagerCache]);

    const update = useCallback(async (employee:EmployeeForUpdate, old:Employee) => {
        const updatedEmployee = await api.updateEmployee(employee);
        updateCache([updatedEmployee.payload], { [updatedEmployee.payload.guid]: old });
        queryClient.invalidateQueries([VQueryKey.PayrollInfoEmployees]);
        bustManagerCache([employee]);
        return updatedEmployee;        
    },[api, updateCache,queryClient, bustManagerCache]);
    
    const create = useCallback(async (employee:EmployeeForCreate) => {        
        let createdEmployee = await api.createEmployee(employee);
        updateCache([createdEmployee], {}); //empty map, signals JUST a create
        queryClient.invalidateQueries([VQueryKey.PayrollInfoEmployees]);
        bustManagerCache([employee]);
        return createdEmployee;        
    },[api, updateCache,queryClient, bustManagerCache]);

    const patchVisualCustomizations = useCallback( (employee: Employee, params: VisualCustomizationsCombined) => {
        if(params.user)
        {
            const newEe:Employee = {
                ...employee,
                settings: {
                    ...employee.settings,
                    visualCustomizations: params.user
                }
            }
            updateCache([newEe], { [employee.guid]: employee });
        }
    },[updateCache]);

    //construct a lookup of group guid to employee guid list
    const groupLookupTable:Record<ApiGuid|"0", Record<ApiGuid, true>> = useMemo(() => {
        let lookup:Record<ApiGuid|"0", Record<ApiGuid, true>> = {};

        things.forEach(e => {
            let groupRefKey = e.groupGuid === null ? "0" : e.groupGuid;
            if(!lookup[groupRefKey])
                lookup[groupRefKey] = {}; //create new table for this group (or 0 => no group)

            lookup[groupRefKey][e.guid] = true; //put the employee in the list

            if(useAdditionalGroups)
            {
                e.additionalGroupGuids.forEach(g => {
                    if(!lookup[g])
                        lookup[g] = {}; //create new table for this group (or 0 => no group)
                    lookup[g][e.guid] = true; //put the employee in the list
                });
            }
        });
        return lookup;
    },[things, useAdditionalGroups]);

    const getEmployeesInGroup = useCallback( (groupGuid:ApiGuid|null):ApiGuid[] => {
        let groupRefKey = groupGuid === null ? "0" : groupGuid;
        let eeGuids = groupLookupTable[groupRefKey]; //multigroup aware since lookup table was contructed with it in mind above
        if(eeGuids)
            return Object.keys(eeGuids);
        return []; //no ee's in that group
    },[groupLookupTable]);

    const getEmployeesInGroupList = useCallback( (groupGuid:ApiGuid[]):ApiGuid[] => {
        let eeGuids:ApiGuid[] = [];
        groupGuid.forEach(g => {
            eeGuids.push(...getEmployeesInGroup(g));
        });
        return eeGuids;
    },[getEmployeesInGroup]);

    // const getEmployeesInOtherEmployeesGroups = useCallback( (referenceEmployee:Employee) => {
    //     return getEmployeeInGroupList(EmployeeHelpers.getEmployeeGroupGuidsFromEmployee(useAdditionalGroups, referenceEmployee));
    // },[getEmployeeInGroupList, useAdditionalGroups]);

    return {
        ...restUseThingsResp,
        employees: things,
        createUpdateList,
        update,
        create,
        getByGuid,
        getEmployeesInGroup, 
        getEmployeesInGroupList,
        patchVisualCustomizations
        // getEmployeesInOtherEmployeesGroups
    }
}


export function useCombineEmployeeAndGroupsIntoEmployeesList(props:{
    employeeGuids:ApiGuid[],
    groupGuids:ApiGuid[],
    useEmployeeListProps: UseEmployeeListProps 
})
{
    let employees = useEmployeeList({
        statusToFetch: ['active']
    });

    let { getByGuid:getEmployeeByGuid, getEmployeesInGroup } = employees;
    let employeeList = useMemo(() => {
        let eeList:Employee[] = [];

        //put each employee (for each guid) in the list
        props.employeeGuids.forEach(guid => {
            let e = getEmployeeByGuid(guid);
            if(e)
                eeList.push(e);
        });

        //get each list of ee's by group guid, and put each after lookup in ee list
        props.groupGuids.forEach(g => {
            let eGuidList = getEmployeesInGroup(g);
            eGuidList.forEach(guid => {
                let e = getEmployeeByGuid(guid);
                if(e)
                    eeList.push(e);
            })
        })
        return eeList;
    },[getEmployeesInGroup, props.employeeGuids, props.groupGuids, getEmployeeByGuid]);
    return {
        isLoading:employees.isLoading,
        isFetched:employees.isFetched,
        loadError:employees.loadError,
        employees:employeeList
    }
}

export function useEmployeeListWithSelf(props:UseEmployeeListProps & { includeSelf?: boolean })
{
    const selfUser = useV().getUser();
    const {
        isLoading,
        loadError,
        getByGuid:originalGetByGuid,
        getEmployeesInGroup,    //no special consideration needed - managers managing self will get themselves, otherwise they are not in the list. Admin / reg will be fine
        employees:originalEmployees,
    } = useEmployeeList(props);

    //wrap relevent results to inject user and return those instead of the original
    const getByGuid = useCallback((guid:ApiGuid) => {
        const e = originalGetByGuid(guid);
        if(props.includeSelf && !e && guid === selfUser.guid)
            return selfUser;
        return e;
    },[originalGetByGuid, selfUser,  props.includeSelf]);

    const employees = useMemo(() => {
        if(!props.includeSelf)
            return originalEmployees;
        if(originalEmployees.find(e => e.guid === selfUser.guid))
            return originalEmployees; //already in list, do not re-add
        return [...originalEmployees, selfUser]; //create new list and add to it (no sorting?)
    },[originalEmployees, props.includeSelf, selfUser]);

    return {
        isLoading,
        loadError,
        getByGuid,
        getEmployeesInGroup,
        employees
    }
}

const commonUseQueryOptionsCompany:UseQueryOptions<EmployeeDeleteWarningsCheckResponse> = {
    ...ReactQueryCommonUseQueryOptions,
    cacheTime: 0,
}
export function useDeleteEmployeeWarnings(params: {
    enabled: boolean,
    employeeGuid: ApiGuid,
})
{
    const { api } = useVeriClockApi();
    const query = useQuery<EmployeeDeleteWarningsCheckResponse>([VQueryKey.EmployeeDeleteWarnings,params.employeeGuid], async () => {
        const resp = await api.getEmployeeDeleteWarnings({ employeeGuids: [params.employeeGuid]});
        return resp.payload;
    }, {
        enabled: params.enabled,
        ...commonUseQueryOptionsCompany
    });

    const { errorString: loadError } = ReactQueryExtractErrorString(VQueryKey.EmployeeDeleteWarnings, query);
    return {
        isLoading: query.isLoading,
        isFetched: query.isFetched,
        loadError,
        warnings: query.data?.warnings
    }    

}