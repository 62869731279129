import React, { useCallback } from 'react';
import { ReactQueryCommonUseQueryOptions, ReactQueryExtractErrorString, VQueryKey } from "../../../sharedReact/src/hooks/queryHookHelpers";
import { ApiGuid, GetThingListOptions, PayrollInfo, PayrollItemRule, PayrollItemRuleForCreate, PayrollInfoForCreate, PayrollInfoForUpdate } from '../../../vericlock_api/src/types';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { BaseUseThingListProps, ThingListActiveInactiveFetchOption, ThingListDeletedFetchOptions, useThingList, UseThingListProps } from '../../../sharedReact/src/hooks/useThingList';
import { useVeriClockApi } from '../../../sharedReact/src/hooks/ApiProvider';

// WARNING: the cached version of the current employee does not come from node, it's the PHP object
// which has some differences.  When in doubt, skip the cache and fetch directly from node with
// useCache = false
export function usePayrollItem(payrollItemGuid:ApiGuid|null|undefined)
{
    // console.warn('note - this is loading all active/inactive/deleted THEN deleted...odd');
    const pMain = usePayrollItemList({
        statusToFetch: ['active','inactive'], 
        enabled: payrollItemGuid != null  
    });

    //check main list first
    let payrollItem = pMain.payrollItems.find(g => g.guid === payrollItemGuid);    
    //flag to load deleted items, if job isn't found AFTER the list is queried
    const lookInTheTrash = payrollItemGuid ? (pMain.isFetched && !payrollItem) : false;

    //only load/check the deleted jobs, if the activeInactive loaded and it wasn't in there.
    const pDel = usePayrollItemList({
        statusToFetch: ['deleted'], 
        enabled: lookInTheTrash  
    });

    if(lookInTheTrash)
    {
        payrollItem = pDel.payrollItems.find(g => g.guid === payrollItemGuid);        
    }

    //combined isLoading
    const isLoading = payrollItemGuid ? (pMain.isLoading || lookInTheTrash && pDel.isLoading) : false;
    //combined loadError
    let loadError = null;
    if(payrollItemGuid)
    {
        const errs:string[] = [];
        if(pMain.loadError)
            errs.push(pMain.loadError)
        if(lookInTheTrash && pDel.loadError)
            errs.push(pDel.loadError);
        if(errs.length)
            loadError = errs.join(','); 
    }

    return {
        payrollItem,
        isLoading,
        isFetched:pMain.isFetched,
        loadError,
        update:pMain.update,
        create:pMain.create
    }
}

const commonUseQueryOptionsPayrollItem:UseQueryOptions<PayrollInfo[]> = {
    ...ReactQueryCommonUseQueryOptions
}
//NOTE by making these constants, the queries are safely cached - RQ treats array order as a unique key, so 'a','b' != 'b','a'

export type UsePayrollItemListProps = BaseUseThingListProps;

//const to ensure stable ref between renders
const activeInactiveQueryKey:UseThingListProps<PayrollInfo>['activeInactiveQueryKey'] = [VQueryKey.PayrollItems, ThingListActiveInactiveFetchOption, false];
const deletedQueryKey:UseThingListProps<PayrollInfo>['deletedQueryKey'] = [VQueryKey.PayrollItems, ThingListDeletedFetchOptions, false];
const activeInactiveQueryKeyTeamView:UseThingListProps<PayrollInfo>['activeInactiveQueryKey'] = [VQueryKey.PayrollItems, ThingListActiveInactiveFetchOption, true];
const deletedQueryKeyTeamView:UseThingListProps<PayrollInfo>['deletedQueryKey'] = [VQueryKey.PayrollItems, ThingListDeletedFetchOptions, true];


export function usePayrollItemList(props:UsePayrollItemListProps)
{
    const { api } = useVeriClockApi();
    const fetchListCallback = useCallback(async (options:GetThingListOptions) => {
        return (await api.queryPayrollInfo(options)).payload;
    },[api]);

    const payrollITemUseThingListResp = useThingList({
        ...props,
        fetchListCallback,
        commonUseQueryOptions: commonUseQueryOptionsPayrollItem,
        activeInactiveQueryKey: props.teamView ? activeInactiveQueryKeyTeamView : activeInactiveQueryKey,
        deletedQueryKey: props.teamView ? deletedQueryKeyTeamView : deletedQueryKey,
        type:'payrollItem'  
    });
    const { updateCache, things, ...restUseThingsResp } = payrollITemUseThingListResp; //.updateCache;

    // const createUpdateList = useCallback(async (employees:(GroupForCreate|GroupForUpdate)[], oldEmployeesMap:Record<ApiGuid, Employee>) => {
    //     const r = await v.createUpdateGroupList(employees);
    //     updateCache(r, oldEmployeesMap);
    //     return r;
    // },[v,updateCache]);

    const update = useCallback(async (item:PayrollInfoForUpdate, old:PayrollInfo) => {
        const updatedItem = (await api.updatePayrollItem(item)).payload;
        updateCache([updatedItem], { [old.guid]: old });
        return updatedItem;        
    },[api, updateCache]);
    
    const create = useCallback(async (item:PayrollInfoForCreate) => {        
        let createdItem = (await api.createPayrollItem(item)).payload;
        updateCache([createdItem], {}); //empty map, signals JUST a create
        return createdItem;        
    },[api, updateCache]);


    return {
        ...restUseThingsResp,
        payrollItems: things,
        // createUpdateList,
        update,
        create
    }
}


const commonUseQueryOptionsPayrollItemRules:UseQueryOptions<PayrollItemRule[]> = {
    ...ReactQueryCommonUseQueryOptions
}
export function usePayrollItemRules()
{
    const { api } = useVeriClockApi();
    const queryClient = useQueryClient();
    const query = useQuery<PayrollItemRule[]>(VQueryKey.PayrollItemRules, async () => (await api.getPayrollItemRules()).payload, {
        // enabled: fetchActiveInactive, 
        ...commonUseQueryOptionsPayrollItemRules
    });

    const findRuleIndex = useCallback( (rule:PayrollItemRuleForCreate) => {

        let ruleList = queryClient.getQueryData<PayrollItemRule[]>(VQueryKey.PayrollItemRules); 
        if(!ruleList) //no list, there for not in the list
            return -1;

        return ruleList.findIndex(r => {
            return r.employee === rule.employee &&
                r.group === rule.group &&
                r.job === rule.job &&
                r.serviceItem === rule.serviceItem &&
                r.timeType === rule.timeType;
        });
    },[queryClient]);

    const deleteRule = useCallback( async (rule:PayrollItemRule) => {
        await api.deletePayrollItemRule(rule);
        
        let ruleList = queryClient.getQueryData<PayrollItemRule[]>(VQueryKey.PayrollItemRules); 
        if(!ruleList) //wasn't in the list...odd, ignore
            return;

        //rule is identified by the contents - they are collectively unique
        let index = ruleList.findIndex(r => {
            return r.employee === rule.employee &&
                r.group === rule.group &&
                r.job === rule.job &&
                r.serviceItem === rule.serviceItem &&
                r.timeType === rule.timeType &&
                r.payrollItem === rule.payrollItem;
        });
        if(index >= 0) //if found, remove from list and reset the value of the query
        {
            ruleList = [...ruleList];
            ruleList.splice(index, 1);
            queryClient.setQueryData<PayrollItemRule[]>(VQueryKey.PayrollItemRules, ruleList); 
        }
        
    },[queryClient, api]);

    const createRule = useCallback( async (rule:PayrollItemRuleForCreate) => {
        let resp = await api.createPayrollItemRule(rule);
        let newRule = resp.payload
        
        let ruleList = queryClient.getQueryData<PayrollItemRule[]>(VQueryKey.PayrollItemRules); 
        if(!ruleList)
            ruleList = [newRule];
        else
            ruleList = [...ruleList, newRule]; //new array ref

        queryClient.setQueryData<PayrollItemRule[]>(VQueryKey.PayrollItemRules, ruleList); 
        return newRule;
    },[queryClient,api]);

    const payrollItemRules = React.useMemo(() => {
        if(!query.data)
            return [];
        return query.data;
    },[query.data]);

    const lookup = React.useMemo( () => {
        const quickLookupByJobGuid:Record<ApiGuid, PayrollItemRule[]> = {};
        const quickLookupByEmployeeGuid:Record<ApiGuid, PayrollItemRule[]> = {};
        const quickLookupByGroupGuid:Record<ApiGuid, PayrollItemRule[]> = {};
        const quickLookupByServiceItemGuid:Record<ApiGuid, PayrollItemRule[]> = {};
        const quickLookupByPayrollItemGuid:Record<ApiGuid, PayrollItemRule[]> = {};
        
        if(payrollItemRules)
        {
            payrollItemRules.forEach(rule => {
                if(!quickLookupByJobGuid[rule.job])
                    quickLookupByJobGuid[rule.job] = [];
                quickLookupByJobGuid[rule.job].push(rule);  //add the rule to the job's rule array

                if(!quickLookupByEmployeeGuid[rule.employee])
                    quickLookupByEmployeeGuid[rule.employee] = [];
                quickLookupByEmployeeGuid[rule.employee].push(rule);  //add the rule to the job's rule array

                if(!quickLookupByGroupGuid[rule.group])
                    quickLookupByGroupGuid[rule.group] = [];
                quickLookupByGroupGuid[rule.group].push(rule);  //add the rule to the job's rule array                    

                if(!quickLookupByServiceItemGuid[rule.serviceItem])
                    quickLookupByServiceItemGuid[rule.serviceItem] = [];
                quickLookupByServiceItemGuid[rule.serviceItem].push(rule);  //add the rule to the job's rule array

                if(!quickLookupByPayrollItemGuid[rule.payrollItem])
                    quickLookupByPayrollItemGuid[rule.payrollItem] = [];
                quickLookupByPayrollItemGuid[rule.payrollItem].push(rule);  //add the rule to the job's rule array
            })
        }
        
        const getRulesByJobGuid = (jobGuid:ApiGuid):PayrollItemRule[]|undefined => {
            return quickLookupByJobGuid[jobGuid]
        }
        const getRulesByEmployeeGuid = (guid:ApiGuid):PayrollItemRule[]|undefined => {
            return quickLookupByEmployeeGuid[guid]
        }
        const getRulesByGroupGuid = (guid:ApiGuid):PayrollItemRule[]|undefined => {
            return quickLookupByGroupGuid[guid]
        }
        const getRulesByServiceItemGuid = (guid:ApiGuid):PayrollItemRule[]|undefined => {
            return quickLookupByServiceItemGuid[guid]
        }
        const getRulesByPayrollItemGuid = (guid:ApiGuid):PayrollItemRule[]|undefined => {
            return quickLookupByPayrollItemGuid[guid]
        }
        return {
            getRulesByJobGuid,
            getRulesByEmployeeGuid,
            getRulesByGroupGuid,
            getRulesByServiceItemGuid,
            getRulesByPayrollItemGuid
        }
    },[payrollItemRules])

    const { errorString: loadError } = ReactQueryExtractErrorString(VQueryKey.PayrollItemRules, query);
    return {
        isLoading: query.isLoading,
        isFetched: query.isFetched,
        loadError: loadError,
        payrollItemRules,
        deleteRule,
        createRule,
        findRuleIndex,
        lookup
    }      
}