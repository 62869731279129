
import { useMemo } from 'react';
import { ApiGuid } from '../../../vericlock_api/src/types/ApiGuid';
import { CustomFieldListItem } from '../../../vericlock_api/src/types/CustomField';
import { EmployeeForPunchClock, JobForPunchClock, ServiceItemForPunchClock } from '../../../vericlock_api/src/types/PunchClock';
import { sortHelperCustomFieldListCodeAsc, sortHelperCustomFieldListCodeDesc, sortHelperCustomFieldListNameAsc, sortHelperCustomFieldListNameDesc, sortHelperEmployeeFirstMiddleLast, sortHelperEmployeeIdAsc, sortHelperEmployeeIdDesc, sortHelperEmployeeLastFirstMiddle, sortHelperJobCodeAsc, sortHelperJobCodeDesc, sortHelperJobNameAsc, sortHelperJobNameDesc, sortHelperServiceItemCodeAsc, sortHelperServiceItemCodeDesc, sortHelperServiceItemNameAsc, sortHelperServiceItemNameDesc } from '../../../lib/src/formatting/sortHelpers';
import { ThingStatus } from '../../../vericlock_api/src/types';
import { VisualCustomizations } from '../../../vericlock_api/src/types/VisualCustomizations';

//from php customization code - was picking first from list
export const defaultRenderTemplateEmployee = "%id - %first%middle%ls%last";
export const defaultRenderTemplateJob = "%code - %name";
export const defaultRenderTemplateServiceItem = "%code - %name";
export const defaultRenderTemplateCustomFieldList = "%code - %name";

// const defaultSortEmployee = 'idAsc';
// const defaultSortJob = 'idAsc';
// const defaultSortServiceItem = 'idAsc';
// const defaultSortCustomFieldList = 'idAsc';

export function renderEmployee(employee:Omit<EmployeeForPunchClock,"settings"|"type">, renderTemplate:string|undefined|null|"default")
{
    if(!renderTemplate || renderTemplate === 'default')
        renderTemplate = defaultRenderTemplateEmployee

    const id = employee.phoneID;
    const first = employee.firstName;
    let middle = employee.middleName;
    let last = employee.lastName;
    let mdot = "";
	if(middle && middle.length)
    {
        mdot = ' ' + middle[0] + '.';
        middle = ' ' + middle; //add space for middle
    }
    else
    {
        mdot = '';
        middle = '';
    }
    let lastSpace = '';
    let lastComma = '';
    if(last && last.length)
    {
        lastSpace = ' ';
        lastComma = ',';
    }
    else
    {
        lastSpace = '';
        lastComma = '';
        last = '';
    }

    let str = renderTemplate;
    str = str.replace(/%id/g, id); //use format here, then use the new str on subsequent ones
    str = str.replace(/%first/g, first); //use format here, then use the new str on subsequent ones

    str = str.replace(/%middle/g, middle); //use format here, then use the new str on subsequent ones
    str = str.replace(/%mdot/g, mdot); //use format here, then use the new str on subsequent ones
    str = str.replace(/%ls/g, lastSpace); //use format here, then use the new str on subsequent ones
    str = str.replace(/%lastComma/g, lastComma); //use format here, then use the new str on subsequent ones
    str = str.replace(/%last/g, last); //use format here, then use the new str on subsequent ones
    return(str);
}
export function renderJob(job:Pick<JobForPunchClock,'code'|'name'>, renderTemplate:string|undefined|null|"default")
{
    if(!renderTemplate || renderTemplate === 'default')
        renderTemplate = defaultRenderTemplateJob
    
	const code = job.code;
    const name = job.name;

    let str = renderTemplate;
    str = str.replace(/%code/g, code.toString()); //use format here, then use the new str on subsequent ones
    str = str.replace(/%name/g, name); //use format here, then use the new str on subsequent ones
    return(str);
}
export function renderServiceItem(item:Pick<ServiceItemForPunchClock,'serviceItemCode'|'name'>, renderTemplate:string|undefined|null|"default")
{
    if(!renderTemplate || renderTemplate === 'default')
        renderTemplate = defaultRenderTemplateServiceItem
    
	const code = item.serviceItemCode;
    const name = item.name;

    let str = renderTemplate;
    str = str.replace(/%code/g, code.toString()); //use format here, then use the new str on subsequent ones
    str = str.replace(/%name/g, name); //use format here, then use the new str on subsequent ones
    return(str);
}
export function renderCustomFieldListItem(item:Pick<CustomFieldListItem,'code'|'name'>, renderTemplate:string|undefined|null|"default")
{
    if(!renderTemplate || renderTemplate === 'default')
        renderTemplate = defaultRenderTemplateCustomFieldList
    
	const code = item.code;
    const name = item.name;

    let str = renderTemplate;
    str = str.replace(/%code/g, code.toString()); //use format here, then use the new str on subsequent ones
    str = str.replace(/%name/g, name); //use format here, then use the new str on subsequent ones
    return(str);
}

type SelectorTypeToType<T> = T extends EmployeeForPunchClock ? 'employee'
    : T extends JobForPunchClock ? 'job'
    : T extends ServiceItemForPunchClock ? 'serviceItem'
    : T extends CustomFieldListItem ? 'customFieldListItem'
    : never

export function customLabelCallback<T extends EmployeeForPunchClock|JobForPunchClock|ServiceItemForPunchClock|CustomFieldListItem>(params:{
        viewingEmployeeGuid?:ApiGuid|null, //absent means has to use company version only
    itemGuid:ApiGuid,
    getEmployeeByGuid:(guid:ApiGuid) => (EmployeeForPunchClock|undefined|null), 
    getByGuid:(guid:ApiGuid) => (T|undefined|null), 
    companyVisualCustomizations:VisualCustomizations|undefined,
    notFoundLabel:string
    useCompanyRenderOnly?:boolean,
    render: (item:T, renderTemplate:string|undefined|null|"default") => string
    // type: 'employee'|'job'
    type: SelectorTypeToType<T>
    // type: T extends EmployeeForPunchClock ? 'employee'
    //     : 'job'
    //     : T extends JobForPunchClock ? keyof Pick<VisualCustomizations['lists']['render'],'job'> //JobForPunchClock ? 'job'
    //     : 'job'
    statusSuffix?: {    //append a suffix to the name if the items 'status' is set to one of these values
        active?: string,
        inactive?: string,
        deleted?: string,
    }
})
{
    const { render, type, viewingEmployeeGuid, itemGuid, getByGuid, getEmployeeByGuid, companyVisualCustomizations, notFoundLabel, useCompanyRenderOnly=false } = params;

    const item = getByGuid(itemGuid);
    const viewingEmployee = viewingEmployeeGuid ? getEmployeeByGuid(viewingEmployeeGuid) : null;
    if(!item)
        return notFoundLabel;

    //only use company if specifically told to, 
    //OR viewing employee is not set OR doesn't have a value for this type
    //OR viewing employee's value is set to the default, then we attemp to use the companies

    const useCompanyValue = useCompanyRenderOnly || 
        !viewingEmployee?.settings.visualCustomizations.lists.render[type] 
        || viewingEmployee.settings.visualCustomizations.lists.render[type] === 'default';
    //use company template if specified, otherwisetry employee THEN company.
    const renderTemplate = (useCompanyValue ? companyVisualCustomizations?.lists.render[type] : //only use company
                         viewingEmployee.settings.visualCustomizations.lists.render[type]) as string|undefined|null|"default"; //needs the type cast here as it barfs without it due to the ListNullable type and some archane TS stuff I know not - if you are reading this, consider it your duty to research and fix. The type is forced in function sig by way of the item type

    const itemName = render(item, renderTemplate);
    if(params.statusSuffix && 
        (type === 'employee' || type === 'job' || type === 'serviceItem'))
    {
        //all have 'status' as a type 
        //todo - fix the generic typing to do this automatically
        const statusSuffix = params.statusSuffix[ (item as { status: ThingStatus }).status];
        if(statusSuffix)
            return itemName + statusSuffix;
    }
    return itemName;
}

export function makeEmployeeSortCompareFunction(sortType:string|null|undefined,getByGuid:(guid:ApiGuid) => (EmployeeForPunchClock|undefined|null)):DataSorterInfo<EmployeeForPunchClock>
{
    switch(sortType)
    {
    case 'last':
        return {
            itemSorter: sortHelperEmployeeLastFirstMiddle,
            datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                const a = getByGuid(aGuid);
                const b = getByGuid(bGuid);
                return sortHelperEmployeeLastFirstMiddle(a,b);                           
            },
            alphabetic: 'AZ',
            getAlphabeticStartingLetter: (guid:ApiGuid) => {
                const e = getByGuid(guid);
                if(!e)
                    return '';
                    
                if(e.lastName && e.lastName.length)
                    return e.lastName[0];
                return e.firstName[0]                  
            }            
    }
    case 'idDesc':
        return {
            itemSorter: sortHelperEmployeeIdDesc,
            datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
            const a = getByGuid(aGuid);
            const b = getByGuid(bGuid);
            return sortHelperEmployeeIdDesc(a,b);                           
        },
        alphabetic: null
    }
    case 'first':
        return {
            itemSorter: sortHelperEmployeeFirstMiddleLast,
            datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
            const a = getByGuid(aGuid);
            const b = getByGuid(bGuid);
            return sortHelperEmployeeFirstMiddleLast(a,b);
        },
        alphabetic: 'AZ',
        getAlphabeticStartingLetter: (guid:ApiGuid) => {
            const item = getByGuid(guid);
            return item ? item.firstName[0] : '';               
        }
    }
    case 'idAsc':
        default:
            return {
                itemSorter: sortHelperEmployeeIdAsc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
            const a = getByGuid(aGuid);
            const b = getByGuid(bGuid);
            return sortHelperEmployeeIdAsc(a,b);                           
        }   ,
        alphabetic: null
    }     
    }
}
export function makeJobSortCompareFunction(sortType:string|null|undefined,getByGuid:(guid:ApiGuid) => (JobForPunchClock|undefined|null)):DataSorterInfo<JobForPunchClock>
{
    switch(sortType)
    {
        case 'name':
            return {
                itemSorter: sortHelperJobNameAsc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperJobNameAsc(a,b);
                },
                alphabetic: 'AZ',
                getAlphabeticStartingLetter: (guid:ApiGuid) => {
                    const item = getByGuid(guid);
                    return item ? item.name[0] : '';               
                }
            }
        case 'nameDsc':
            return {
                itemSorter: sortHelperJobNameDesc,
                    datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperJobNameDesc(a,b);
                },
                alphabetic: 'ZA',
                getAlphabeticStartingLetter: (guid:ApiGuid) => {
                    const item = getByGuid(guid);
                    return item ? item.name[0] : '';               
                }
            }
        case 'idDesc':
            return {
                itemSorter: sortHelperJobCodeDesc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperJobCodeDesc(a,b);
                },
                alphabetic: null
            }
        case 'idAsc':
        default:
            return {
                itemSorter: sortHelperJobCodeAsc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperJobCodeAsc(a,b);
                },
                alphabetic: null
            }
    }
}
export function makeServiceItemSortCompareFunction(sortType:string|null|undefined,getByGuid:(guid:ApiGuid) => (ServiceItemForPunchClock|undefined|null)):DataSorterInfo<ServiceItemForPunchClock>
{
    switch(sortType)
    {
        case 'name':
            return {
                itemSorter: sortHelperServiceItemNameAsc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperServiceItemNameAsc(a,b);
                },
                alphabetic: 'AZ',
                getAlphabeticStartingLetter: (guid:ApiGuid) => {
                    const item = getByGuid(guid);
                    return item ? item.name[0] : '';               
                }
            }
        case 'nameDsc':
            return {
                itemSorter: sortHelperServiceItemNameDesc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperServiceItemNameDesc(a,b);
                },
                alphabetic: 'ZA',
                getAlphabeticStartingLetter: (guid:ApiGuid) => {
                    const item = getByGuid(guid);
                    return item ? item.name[0] : '';               
                }
            }
        case 'idDesc':
            return {
                itemSorter: sortHelperServiceItemCodeDesc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperServiceItemCodeDesc(a,b);
                },
                alphabetic: null
            }
        case 'idAsc':
        default:
            return {
                itemSorter: sortHelperServiceItemCodeAsc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperServiceItemCodeAsc(a,b);
                },
                alphabetic: null
            }
    }
}
export function makeCustomFieldListSortCompareFunction(sortType:string|null|undefined,getByGuid:(guid:ApiGuid) => (CustomFieldListItem|undefined|null)):DataSorterInfo<CustomFieldListItem>
{
    const getAlphabeticStartingLetter = (guid:ApiGuid) => {
        const item = getByGuid(guid);
        return item ? item.name[0] : ''; 
    }
    switch(sortType)
    {
        case 'name':
            return {
                itemSorter: sortHelperCustomFieldListNameAsc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperCustomFieldListNameAsc(a,b);
                    },
                    alphabetic: 'AZ',
                    getAlphabeticStartingLetter
                }
        case 'nameDsc':
            return {
                itemSorter: sortHelperCustomFieldListNameDesc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperCustomFieldListNameDesc(a,b);
                    },
                    alphabetic: 'ZA',
                    getAlphabeticStartingLetter
                }
        case 'idDesc':
            return {
                itemSorter: sortHelperCustomFieldListCodeDesc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperCustomFieldListCodeDesc(a,b);
                },
                alphabetic: null
        }
        case 'idAsc':
        default:
            return {
                itemSorter: sortHelperCustomFieldListCodeAsc,
                datasorter: (aGuid:ApiGuid, bGuid:ApiGuid) => {
                    const a = getByGuid(aGuid);
                    const b = getByGuid(bGuid);
                    return sortHelperCustomFieldListCodeAsc(a,b);
                },
                alphabetic: null
            }
    }
}
type SortableThing = EmployeeForPunchClock|JobForPunchClock|ServiceItemForPunchClock|CustomFieldListItem;
type DataSorterInfo<T extends SortableThing> = {
    datasorter: (a:ApiGuid, b:ApiGuid) => number,
    alphabetic: 'AZ'|'ZA'|null,
    getAlphabeticStartingLetter?:(item:ApiGuid) => string,
    itemSorter: (a:T, b:T) => number
}

export function useCompanySortingPreference<T extends SortableThing>(params:{
    viewingEmployee?:EmployeeForPunchClock|null, //absent means has to use company version only
    companyVisualCustomizations:VisualCustomizations|undefined,
    useCompanySortOnly?:boolean,
    type: SelectorTypeToType<T>,
})
{
    const { viewingEmployee, companyVisualCustomizations, useCompanySortOnly=false,type  } = params;

    const useCompanyValue = useCompanySortOnly || 
    !viewingEmployee?.settings.visualCustomizations.lists.sort[type] 
    || viewingEmployee.settings.visualCustomizations.lists.sort[type] === 'default';
    //use company template if specified, otherwisetry employee THEN company.
    const sortType = (useCompanyValue ? companyVisualCustomizations?.lists.sort[type] : //only use company
                     viewingEmployee.settings.visualCustomizations.lists.sort[type]) as string|undefined|null|"default"; //needs the type cast here as it barfs without it due to the ListNullable type and some archane TS stuff I know not - if you are reading this, consider it your duty to research and fix. The type is forced in function sig by way of the item type

    return useMemo(() => {
        return sortType;
    },[sortType]);
}

export function useCustomSorter<T extends SortableThing>(params:{
    viewingEmployeeGuid?:ApiGuid|null, //absent means has to use company version only
    getEmployeeByGuid:(guid:ApiGuid) => (EmployeeForPunchClock|undefined|null), 
    getByGuid:(guid:ApiGuid) => (T|undefined|null), 
    companyVisualCustomizations:VisualCustomizations|undefined,
    useCompanySortOnly?:boolean,
    type: SelectorTypeToType<T>,
    makeSortCompareFunction: (sortType:string|null|undefined, getByGuid:(guid:ApiGuid) => (T|undefined|null)) => DataSorterInfo<T> 
}): DataSorterInfo<T>
{
    const { getByGuid, makeSortCompareFunction } = params;
    const sortType = useCompanySortingPreference(params);

    return useMemo(() => {
        return makeSortCompareFunction(sortType, getByGuid);
    },[makeSortCompareFunction, sortType, getByGuid]);
    
}