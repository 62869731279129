import { ServiceItemRule, ServiceItemRuleEngineLookupFunctionParams } from "../../../vericlock_api/src/types/JobRule";
import { ApiGuid } from "../../../vericlock_api/src/types/ApiGuid";

export function scoreServiceItemRule(rule:ServiceItemRule)
{
        // Ranking order is from highest to lowest:         [this is the way]
        // Employee + Job + Group                   >> Employee + Job + Group                   >> Employee + Job + Group
        // Employee + Job +                         >> Employee + Job +   *                     >> Employee +  *  + Group   <- rare
        // Employee +     +  Group <<--WEIRD        >> Employee +  *  + Group   <- rare         >> Employee + Job +   *
        // Employee                                 >> Employee +  *  +   *                     >> Employee +  *  +   *
        //          + Job + Group                   >>     *    + Job + Group                   >>     *    + Job + Group
        //                  Group                   >>     *    + Job +   *                     >>     *    +  *  + Group
        //          + Job                           >>     *    +  *  + Group                   >>     *    + Job +   *
        // Any      + Any                           >>     *    +  *  +   *                     >>     *    +  *  +   *

        //attempt to mimic prioritization chart above and produce a proportionate score for sorting in list
    var e = rule.employee === 'any' ? false : true;
    var g = rule.group === 'any' ? false : true;
    var j = rule.job === 'any' ? false : true;
    
    var score = 0;
    if(e && j && g) { return(score); } score++; //all set
    if(e && j && !g) { return(score); } score++; 
    if(e && !j && g) { return(score); } score++; 
    if(e && !j && !g) { return(score); } score++; 

    if(!e && j && g) { return(score); } score++; 
    if(!e && j && !g) { return(score); } score++; 
    if(!e && !j && g) { return(score); } score++; 
    if(!e && !j && !g) { return(score); } score++; 

    console.log('yea doth not see me!')
    return(score);
}


function serviceItemRuleSortMostSpecificToLeastSpecific(a:ServiceItemRule, b:ServiceItemRule)
{
    return scoreServiceItemRule(a) - scoreServiceItemRule(b);
}

export function createServiceItemRuleEngine(rules:ServiceItemRule[]|undefined)
{
    const sortedRuleList = rules ? [...rules].sort(serviceItemRuleSortMostSpecificToLeastSpecific) : [];

    const doesRuleMatch = (rule:ServiceItemRule, criteria: {employeeGuid:ApiGuid|null, orderedGroupGuids:ApiGuid[], jobGuid:ApiGuid|null} ) => {
        if(!(rule.employee === 'any' || rule.employee === criteria.employeeGuid))
            return false; //neither any nor the specific employee 
        if(!(rule.group === 'any' || criteria.orderedGroupGuids?.includes(rule.group)))
            return false; //neither any nor the specific group
        if(!(rule.job === 'any' || rule.job === criteria.jobGuid))
            return false; //neither any nor the specific employee 
                    
        //if we fall through, then the rule matches
        return true;
    }
    const getMostSpecificRule = (criteria: {employeeGuid:ApiGuid|null, orderedGroupGuids:ApiGuid[], jobGuid:ApiGuid|null} ) => {
        for(let i=0; i < sortedRuleList.length; i++)
        {
            if(doesRuleMatch(sortedRuleList[i], criteria)) //if the rule matches, we send it back
            {
                // Multigroup makes this a bit more complicated.  There could be any number of rules with the same score where only the group changes.  If that's
                // the case we need to potentially iterate through all of them and find the one that matches the highest group priority for that employees.

                // If the employee only belongs to one or no group, then no need go any further, only one rule can ever have this score.
                if (criteria.orderedGroupGuids.length<2)
                    return sortedRuleList[i];

                let bestMatchedRule = sortedRuleList[i];
                const matchedScore = scoreServiceItemRule(bestMatchedRule);

                while(++i<sortedRuleList.length) {
                    // if the next rule has a different score, then it's a lesser match and stop.
                    if (scoreServiceItemRule(sortedRuleList[i])!==matchedScore)
                    {
                        break;
                    }

                    //Both rules have the same score, figure out which one has the highest priority group
                    for(let j=0;j<criteria.orderedGroupGuids.length;j++)
                    {
                        if (bestMatchedRule.group===criteria.orderedGroupGuids[j])
                        {
                            // current best matched rule is best, keep it.
                            break;
                        }
                        if (sortedRuleList[i].group===criteria.orderedGroupGuids[j])
                        {
                            // new rule has a higher priority group, use it instead.
                            bestMatchedRule = sortedRuleList[i];
                            break;
                        }
                    }
                } 

                return bestMatchedRule;
            }               
        }
        return null; //no rule found
    }

    //return the rule engine calculation function
    return (options:ServiceItemRuleEngineLookupFunctionParams) => {
        
        if(options.companySetting === 'optional' || options.companySetting === 'required')
            return options.companySetting; //'disabled'|'optional'|'required', we stop here and pass it back
        if(options.companySetting === 'disabled')
            return 'none';
        
        //must be 'custom' if we fall through
        // if(options.callerId.length === 3)
        //     return 'none';

        const rule = getMostSpecificRule( {
            employeeGuid:options.employeeGuid, 
            orderedGroupGuids:options.orderedGroupGuids, 
            jobGuid:options.jobGuid
        })

        if(!rule)
            return 'none';
        
        return rule.serviceItem; //'none'|'optional'|'required'|api guid... 
    }
}