import { ServiceItemRateRule, ServiceItemRateRuleEngineLookupFunctionParams } from "../../../vericlock_api/src/types/JobRule";
import { ApiGuid } from "../../../vericlock_api/src/types/ApiGuid";

export function scoreServiceItemRateRule(rule:ServiceItemRateRule)
{
    //cloned from payroll rules, since they are the same
    // Ranking order is from highest to lowest:
    // E - Employee, G - Group, J - Job, S - Service Item

    // E + G + J + S

    // E + * + J + S
    // E + G + * + S
    // E + G + J + *
    // * + G + J + S

    // E + * + J + *
    // E + * + * + S
    // * + G + J + *
    // * + G + * + S
    // * + * + J + S
    // E + G + * + *

    // * + * + J + *
    // * + * + * + S
    // E + * + * + *
    // * + G + * + *

    // * + * + * + *

        //attempt to mimic prioritization chart above and produce a proportionate score for sorting in list
    var e = rule.employee === 'any' ? false : true;
    var g = rule.group === 'any' ? false : true;
    var j = rule.job === 'any' ? false : true;
    var s = rule.serviceItem === 'any' ? false : true;

    var score = 0;
    if(e && g && j && s) { return(score); } score++; //all set
    
    if(e  && !g && j  && s) { return(score); } score++; 
    if(e  && g  && !j && s) { return(score); } score++; 
    if(e  && g  && j  && !s) { return(score); } score++; 
    if(!e && g  && j  && s) { return(score); } score++; 

    // E + * + J + *
    // E + * + * + S
    // * + G + J + *
    // * + G + * + S
    // * + * + J + S
    // E + G + * + *
    if(e  && !g && j  && !s) { return(score); } score++; 
    if(e  && !g && !j && s) { return(score); } score++; 
    if(!e && g  && j  && !s) { return(score); } score++; 
    if(!e && g  && !j && s) { return(score); } score++; 
    if(!e && !g && j  && s) { return(score); } score++; 
    if(e  && g  && !j && !s) { return(score); } score++; 

    // * + * + J + *
    // * + * + * + S
    // E + * + * + *
    // * + G + * + *
    if(!e && !g && j  && !s) { return(score); } score++; 
    if(!e && !g && !j && s) { return(score); } score++; 
    if(e  && !g && !j && !s) { return(score); } score++; 
    if(!e && g  && !j && !s) { return(score); } score++; 

    // * + * + * + *
    if(!e && !g && !j && !s) { return(score); } score++; 

    console.log('yea doth not see me! - si rate rules')
    return(score);
}


function serviceItemRateRuleSortMostSpecificToLeastSpecific(a:ServiceItemRateRule, b:ServiceItemRateRule)
{
    return scoreServiceItemRateRule(a) - scoreServiceItemRateRule(b);
}

export function createServiceItemRateRuleEngine(rules:ServiceItemRateRule[]|undefined)
{
    const sortedRuleList = rules ? [...rules].sort(serviceItemRateRuleSortMostSpecificToLeastSpecific) : [];

    const doesRuleMatch = (rule:ServiceItemRateRule, criteria: { serviceItem: ApiGuid|null, employeeGuid:ApiGuid|null, orderedGroupGuids:ApiGuid[], jobGuid:ApiGuid|null} ) => {
        if(!(rule.serviceItem === 'any' || rule.serviceItem === criteria.serviceItem))
            return false; //neither any nor the specific serviceItem 
        if(!(rule.employee === 'any' || rule.employee === criteria.employeeGuid))
            return false; //neither any nor the specific employee 
        if(!(rule.group === 'any' || criteria.orderedGroupGuids?.includes(rule.group)))
            return false; //neither any nor the specific group
        if(!(rule.job === 'any' || rule.job === criteria.jobGuid))
            return false; //neither any nor the specific job                     
        //if we fall through, then the rule matches
        return true;
    }
    const getMostSpecificRule = (criteria: {serviceItem: ApiGuid|null, employeeGuid:ApiGuid|null, orderedGroupGuids:ApiGuid[], jobGuid:ApiGuid|null} ) => {
        for(let i=0; i < sortedRuleList.length; i++)
        {
            if(doesRuleMatch(sortedRuleList[i], criteria)) //if the rule matches, we send it back
                return sortedRuleList[i];                    
        }
        return null; //no rule found
    }

    //return the rule engine calculation function
    return (options:ServiceItemRateRuleEngineLookupFunctionParams) => {
        
        //must be 'custom' if we fall through
        // if(options.callerId.length === 3)
        //     return 'none';

        const rule = getMostSpecificRule( {
            serviceItem: options.serviceItem,
            employeeGuid:options.employeeGuid, 
            orderedGroupGuids:options.orderedGroupGuids,
            jobGuid:options.jobGuid
        })

        if(!rule)        
            return null;
        
        return rule; //'none'|'optional'|'required'|api guid... 
    }
}