import { ServiceItem } from "../../../../models/serviceItem";
import { CustomFieldListItem } from "../../../vericlock_api/src/types/CustomField";
import { Employee } from "../../../vericlock_api/src/types/Employee";
import { Job } from "../../../vericlock_api/src/types/Job";
import { makeEmployeeFullName } from "../helpers/employeeFullName";

type EmployeeForSort = Pick<Employee,'firstName'|'lastName'|'middleName'|'phoneID'|'fullName'>;
type JobForSort = Pick<Job,'code'|'name'>;
type ServiceItemForSort = Pick<ServiceItem, 'name'|'serviceItemCode'>;
type CustomFieldListItemForSort = Pick<CustomFieldListItem,'code'|'name'>

function oneIsNullComparator(a:any, b:any)
{
    if(!a && b) //aName missing
        return 1; //a goes AFTER b
    if(a && !b)
        return -1; //a goes BEFORE b
    return 0; //same - todo: should we maybe compare guids? to make it stable..though they won't be labelled probably so does it matter?
}
export function sortHelperEmployeeFirstMiddleLast(a:EmployeeForSort|null|undefined,b:EmployeeForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    return a.fullName.toLocaleLowerCase().localeCompare(b.fullName.toLocaleLowerCase());    
}
export function sortHelperEmployeeLastFirstMiddle(a:EmployeeForSort|null|undefined,b:EmployeeForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    const aName = makeEmployeeFullName(false, a);
    const bName = makeEmployeeFullName(false, b);
    return aName.toLocaleLowerCase().localeCompare(bName.toLocaleLowerCase());
}
export function sortHelperEmployeeIdAsc(a:EmployeeForSort|null|undefined,b:EmployeeForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    const idA = parseInt(a.phoneID);
    const idB = parseInt(b.phoneID);
    return idA - idB;
}
export function sortHelperEmployeeIdDesc(a:EmployeeForSort|null|undefined,b:EmployeeForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    const idA = parseInt(a.phoneID);
    const idB = parseInt(b.phoneID);
    return idB - idA;
}
export function sortHelperJobCodeAsc(a:JobForSort|null|undefined, b:JobForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    return a.code - b.code;
}
export function sortHelperJobCodeDesc(a:JobForSort|null|undefined, b:JobForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    return b.code - a.code;
}

export function sortHelperJobNameAsc(a:JobForSort|null|undefined, b:JobForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);
    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
}

export function sortHelperJobNameDesc(a:JobForSort|null|undefined, b:JobForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);
    return b.name.toLocaleLowerCase().localeCompare(a.name.toLocaleLowerCase());
}

export function sortHelperServiceItemNameAsc(a:ServiceItemForSort|null|undefined, b:ServiceItemForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);
    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
}

export function sortHelperServiceItemNameDesc(a:ServiceItemForSort|null|undefined, b:ServiceItemForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);
    return b.name.toLocaleLowerCase().localeCompare(a.name.toLocaleLowerCase());
}
export function sortHelperServiceItemCodeAsc(a:ServiceItemForSort|null|undefined, b:ServiceItemForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    return a.serviceItemCode - b.serviceItemCode;
}
export function sortHelperServiceItemCodeDesc(a:ServiceItemForSort|null|undefined, b:ServiceItemForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    return b.serviceItemCode - a.serviceItemCode;
}

export function sortHelperCustomFieldListCodeAsc(a:CustomFieldListItemForSort|null|undefined, b:CustomFieldListItemForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    return a.code - b.code;
}
export function sortHelperCustomFieldListCodeDesc(a:CustomFieldListItemForSort|null|undefined, b:CustomFieldListItemForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);

    return b.code - a.code;
}

export function sortHelperCustomFieldListNameAsc(a:CustomFieldListItemForSort|null|undefined, b:CustomFieldListItemForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);
    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
}

export function sortHelperCustomFieldListNameDesc(a:CustomFieldListItemForSort|null|undefined, b:CustomFieldListItemForSort|null|undefined)
{
    if(!a || !b) return oneIsNullComparator(a,b);
    return b.name.toLocaleLowerCase().localeCompare(a.name.toLocaleLowerCase());
}