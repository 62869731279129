import { useCallback } from "react";
import { QueryClient, useQuery, UseQueryOptions } from "react-query";
import { EmployeeIntegrationMap, JobIntegrationMap, PayrollItemIntegrationMap, ServiceItemIntegrationMap } from "../../../vericlock_api/src/types/IntegrationMaps";
import { ApiGuid } from "../../../vericlock_api/src/types/ApiGuid";
import { VQueryKey, ReactQueryExtractErrorString, ReactQueryCommonUseQueryOptions } from "../../../sharedReact/src/hooks/queryHookHelpers";
import { useVeriClockApi } from "../../../sharedReact/src/hooks/ApiProvider";

//todo - careful - integration maps need clearing out when a job is deleted, or edited with the relevent items
// on job create/update, nullify this for simplicity


const commonUseQueryOptionsTimeTypes:UseQueryOptions<any> = {
    ...ReactQueryCommonUseQueryOptions
}

//of a job is created/updated, invalidate all integration maps in memory to ensure we reload them to get correct data
export function invalidateAllIntegrationMapQueriesOnJobCreateUpdate(queryClient:QueryClient) 
{
    queryClient.invalidateQueries(VQueryKey.EmployeeIntegrationMaps);
    queryClient.invalidateQueries(VQueryKey.JobIntegrationMaps);
    queryClient.invalidateQueries(VQueryKey.ServiceItemIntegrationMaps);
    queryClient.invalidateQueries(VQueryKey.PayrollItemIntegrationMaps);
}

export function useJobIntegrationMaps(props:{enabled?:boolean})
{
    const options = props ?? {};
    const { enabled=true } = options;

    const { api } = useVeriClockApi();
    return  useIntegrationMap(enabled, VQueryKey.JobIntegrationMaps, async () => (await api.getJobIntegrationMaps({})).payload)
}
export function useEmployeeIntegrationMaps(props:{enabled?:boolean})
{
    const options = props ?? {};
    const { enabled=true } = options;
    const { api } = useVeriClockApi();
    return  useIntegrationMap(enabled, VQueryKey.EmployeeIntegrationMaps, async () => (await api.getEmployeeIntegrationMaps({})).payload)
}
export function useServiceItemIntegrationMaps(props:{enabled?:boolean})
{
    const options = props ?? {};
    const { enabled=true } = options;
    const { api } = useVeriClockApi();
    return  useIntegrationMap(enabled, VQueryKey.ServiceItemIntegrationMaps, async () => (await api.getServiceItemIntegrationMaps({})).payload)
}
export function usePayrollItemIntegrationMaps(props:{enabled?:boolean})
{
    const options = props ?? {};
    const { enabled=true } = options;
    const { api } = useVeriClockApi();
    return  useIntegrationMap(enabled, VQueryKey.PayrollItemIntegrationMaps, async () => (await api.getPayrollItemIntegrationMaps({})).payload)
}
type AllowedQueryKeys = VQueryKey.JobIntegrationMaps|VQueryKey.ServiceItemIntegrationMaps|VQueryKey.PayrollItemIntegrationMaps|VQueryKey.EmployeeIntegrationMaps;

type IntegrationMap<D=AllowedQueryKeys> = 
D extends VQueryKey.JobIntegrationMaps ? JobIntegrationMap :
D extends VQueryKey.ServiceItemIntegrationMaps ? ServiceItemIntegrationMap :
D extends VQueryKey.PayrollItemIntegrationMaps ? PayrollItemIntegrationMap :
D extends VQueryKey.EmployeeIntegrationMaps ? EmployeeIntegrationMap : never;

//typeguards for above sub types
function isJobIntegrationMap(m:any):m is JobIntegrationMap
{
    return m && m['jobGuid'];
}
function isServiceItemIntegrationMap(m:any):m is ServiceItemIntegrationMap
{
    return m && m['serviceItemGuid'];
}
function isPayrollItemIntegrationMap(m:any):m is PayrollItemIntegrationMap
{
    return m && m['payrollItemGuid'];
}
function isEmployeeIntegrationMap(m:any):m is EmployeeIntegrationMap
{
    return m && m['employeeGuid'];
}

function useIntegrationMap<Q extends AllowedQueryKeys, D extends IntegrationMap<Q>>(enabled:boolean, queryKey:Q, loaderCallback:() => Promise<D[]>)
// function useIntegrationMap<K=jiType|siType, D=K extends jiType ? JobIntegrationMap : ServiceItemIntegrationMap>(key:K, loaderCallback:() => Promise<D>)
{
    // const queryClient = useQueryClient();
    const query = useQuery<D[]>(queryKey, loaderCallback, {
        enabled,
        ...commonUseQueryOptionsTimeTypes
    });
    const isLoading = query.isLoading;
    const { errorString: loadError } = ReactQueryExtractErrorString(queryKey, query);
    const integrationMaps = query.data;
    
    const getMapsByGuid = useCallback((itemGuid:ApiGuid) => {
        if(!integrationMaps)
            return [];
        
        let maps:D[] = [];
        
        integrationMaps.forEach(m => {
            let mapGuid:ApiGuid;

            //todo - this isn't typed right - queryKey's type should coerce the type of integration maps ... maybe in foreach cannot/
            if(queryKey === VQueryKey.JobIntegrationMaps && isJobIntegrationMap(m))
                mapGuid = m['jobGuid'];
            else if(queryKey === VQueryKey.EmployeeIntegrationMaps && isEmployeeIntegrationMap(m))
                mapGuid = m['employeeGuid'];
            else if(queryKey === VQueryKey.ServiceItemIntegrationMaps && isServiceItemIntegrationMap(m))
                mapGuid = m['serviceItemGuid'];
            else if(queryKey === VQueryKey.PayrollItemIntegrationMaps && isPayrollItemIntegrationMap(m))
                mapGuid = m['payrollItemGuid'];
            else
                throw new Error('unknown VQueryKey[454343]: ' + queryKey);

            //  = m[guidKey];
            if(mapGuid === itemGuid)
                maps.push(m);
        });

        return maps;        
    },[integrationMaps, queryKey]);

    return {
        isLoading,
        isFetched: query.isFetched,
        loadError,
        integrationMaps: query.data,
        getMapsByGuid        
    }
}