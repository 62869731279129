import { ApiGuid, Employee, Group, Job, ServiceItem } from '../../../vericlock_api/src/types';

type EmployeeForAccessControl = Pick<Employee,'guid'|'groupGuid'|'additionalGroupGuids'|'type'>;
type ServiceItemForAccessControl = Pick<ServiceItem,'guid'|'employeeAccessControl'|'jobAccessControl'|'employeeAssignment'|'jobAssignment'|'adminCanAssign'>
type JobForAccessControl = Pick<Job,'guid'|'accessControl'|'assignment'|'adminCanAssign'>;
type AccessControlOptions = {
    ignoreAdminStatus?:boolean  //default false - when true, admin status is ignored for access purposes
    allowAdminCanAssign?:boolean //default false - when true, adminCanAssign sis  are allowed - consumer must exercise care when setting 
    useAdditionalGroups:boolean //when true, additionalGroups come into play for access control checks
}
//check the items's access control list and see if employee, or its group is in it (if so configured)
export function canEmployeeAndJobAccessServiceItem(employee:EmployeeForAccessControl, jobGuid:ApiGuid|null, serviceItem:ServiceItemForAccessControl, options: AccessControlOptions):boolean
{
    const { ignoreAdminStatus=false } = options;

    //only consider admin status IF the employee is an admin
    if(!ignoreAdminStatus && employee.type === 'admin')
        return true;

    if(options.allowAdminCanAssign && serviceItem.adminCanAssign)
        return true;
        
    //if we fall through, employee is either allowed or denied based on being in the list or not.  Lets first see if we're in the list (group or employee)
    if(!canEmployeeAccessServiceItem(employee, serviceItem, options))
        return false;

    //fall through, up to whether the job has acces
    return canJobGuidAccessServiceItem(jobGuid,serviceItem, options);
        
}
function canJobGuidAccessServiceItem(jobGuid:ApiGuid|null,serviceItem:ServiceItemForAccessControl, options: AccessControlOptions)
{
    if(options.allowAdminCanAssign && serviceItem.adminCanAssign)
        return true;

    if(serviceItem.jobAccessControl === 'none')
        return true;
    
    const inJobList = jobGuid ? isJobInServiceItemAccessLists(jobGuid, serviceItem) : false; //

    if(serviceItem.jobAccessControl === 'allowList')
        return(inJobList)
    else if(serviceItem.jobAccessControl === 'denyList')
        return(!inJobList)
                
    throw new Error('Unknown type of service item employee access control:' + serviceItem.jobAccessControl);
}

export function canJobAccessServiceItems(job:JobForAccessControl, serviceItems:ServiceItemForAccessControl[], options: AccessControlOptions)
{
    for(let i=0; i < serviceItems.length; i++)
    {
        if(!canJobGuidAccessServiceItem(job.guid, serviceItems[i], options))
            return false;
    }
    return true; //fall through, job can access all
}
export function canJobsAccessServiceItem(jobs:JobForAccessControl[], serviceItem:ServiceItemForAccessControl, options: AccessControlOptions)
{
    for(let i=0; i < jobs.length; i++)
    {
        if(!canJobGuidAccessServiceItem(jobs[i].guid, serviceItem, options))
            return false;
    }
    return true; //fall through, job can access all
}

export function canEmployeesAccessServiceItem(employees:EmployeeForAccessControl[], serviceItem:ServiceItemForAccessControl, options: AccessControlOptions)
{
    for(let i=0; i < employees.length; i++)
    {
        if(!canEmployeeAccessServiceItem(employees[i], serviceItem, options))
            return false;
    }
    return true; //fall through, all must have access
}

export function canGroupsAccessServiceItem(groups:Group[], groupEmployeeGuidsMap:Record<ApiGuid,ApiGuid[]>, serviceItem:ServiceItemForAccessControl,  options: AccessControlOptions)
{
    for(let i=0; i < groups.length; i++)
    {
        if(!canGroupAccessServiceItem(groups[i],groupEmployeeGuidsMap[groups[i].guid], serviceItem, options))
            return false;
    }
    return true; //fall through, all must have access
}
    
function canEmployeeAccessServiceItem(employee:EmployeeForAccessControl, serviceItem:ServiceItemForAccessControl, options: AccessControlOptions):boolean
{
    const { ignoreAdminStatus=false } = options;

    if(options.allowAdminCanAssign && serviceItem.adminCanAssign)
        return true;

    if(serviceItem.employeeAccessControl === 'none')
        return true;

    //only consider admin status IF the employee is an admin
    if(!ignoreAdminStatus && employee.type === 'admin')
        return true;

    const inEmployeeGroupList = isEmployeeInServiceItemAccessLists(employee, serviceItem, options);

    if(serviceItem.employeeAccessControl === 'allowList')
        return(inEmployeeGroupList)
    else if(serviceItem.employeeAccessControl === 'denyList')
        return(!inEmployeeGroupList)
        
    throw new Error('Unknown type of service item employee access control:' + serviceItem.jobAccessControl);
}
export function canEmployeeAccessServiceItems(employee:EmployeeForAccessControl, serviceItems:ServiceItemForAccessControl[], options: AccessControlOptions):boolean
{
    for(let i=0; i < serviceItems.length; i++)
    {
        if(!canEmployeeAccessServiceItem(employee, serviceItems[i], options))
            return false; //early exit
    }
    //fall through, then true
    return true;
}

function canGroupAccessServiceItem(group:Group, groupEmployeeGuids:ApiGuid[], serviceItem:ServiceItemForAccessControl, options: AccessControlOptions):boolean
{
    if(options.allowAdminCanAssign && serviceItem.adminCanAssign)
        return true;

    if(serviceItem.employeeAccessControl === 'none')
        return true;

    const inList = isGroupGuidInServiceItemAccessLists(group.guid, serviceItem);

//cases:
// accessControl is allowedList
//    group is in the list => YES
//    group is NOT in the list => NO

// access control is denyList
//   group is in the list, NO
//   group is not in the list =>  
//      group members individaully in the list, NO (group is blocked)
//      no group members in list => YES (group nor members are not in list, so allowed)
    
    if(serviceItem.employeeAccessControl === 'allowList')
        return inList; //if in list, they are allowed
    else if(serviceItem.employeeAccessControl === 'denyList') {
        for(let i=0; i < groupEmployeeGuids.length; i++)
        {
            if(isEmployeeGuidInServiceItemAccessLists(groupEmployeeGuids[i], serviceItem))
                return false; //one of the groups employees IS blocked, so this group cannot access the job
        }
        return !inList; //deny if in list
    }

    throw new Error('Unknown serviceItem.employeeAccessControl value[g]: ' + serviceItem.employeeAccessControl);
}
export function canGroupAccessServiceItems(group:Group, groupEmployeeGuids:ApiGuid[], serviceItems:ServiceItemForAccessControl[], options: AccessControlOptions):boolean
{
    for(let i=0; i < serviceItems.length; i++)
    {
        if(!canGroupAccessServiceItem(group, groupEmployeeGuids, serviceItems[i], options))
            return false; //early exit
    }
    //fall through, then true
    return true;
}
//ignoring the actual state (allow/deny), is the employee in the access list 
export function isEmployeeInServiceItemAccessLists(employee:EmployeeForAccessControl, serviceItem:ServiceItemForAccessControl, options: Pick<AccessControlOptions,'useAdditionalGroups'>):boolean
{
    if(employee.groupGuid && isGroupGuidInServiceItemAccessLists(employee.groupGuid, serviceItem))
        return true;

    //if we are to use additional groups, check them too
    if(options.useAdditionalGroups && employee.additionalGroupGuids && employee.additionalGroupGuids.length > 0)
    {
        for(let i=0; i < employee.additionalGroupGuids.length; i++)
        {
            if(isGroupGuidInServiceItemAccessLists(employee.additionalGroupGuids[i], serviceItem))
                return true;
        }
    }

    return isEmployeeGuidInServiceItemAccessLists(employee.guid, serviceItem);
}
function isGroupGuidInServiceItemAccessLists(groupGuid:ApiGuid, serviceItem:ServiceItemForAccessControl):boolean
{
    const groupIndex = serviceItem.employeeAssignment.groupGuids ? serviceItem.employeeAssignment.groupGuids.findIndex(g => g === groupGuid) : -1;
    if(groupIndex >= 0)
        return true; //early exit, employees group is in the list
    return false;
}
function isEmployeeGuidInServiceItemAccessLists(employeeGuid:ApiGuid, serviceItem:ServiceItemForAccessControl):boolean
{
    const employeeIndex = serviceItem.employeeAssignment.employeeGuids ? serviceItem.employeeAssignment.employeeGuids.findIndex(e => e === employeeGuid) : -1;
    if(employeeIndex >= 0)
        return true;
    return false;
}

export function isJobInServiceItemAccessLists(jobGuid:ApiGuid, serviceItem:ServiceItemForAccessControl):boolean
{
    const jobIndex = serviceItem.jobAssignment.jobGuids ? serviceItem.jobAssignment.jobGuids.findIndex(guid => guid === jobGuid) : -1;
    return jobIndex >= 0;
}

//creates a filter for a Job[].sort call
//todo: maybe not used anymore!
export function constrainServiceItemsByAssignmentsFilter(constrainForEmployee:EmployeeForAccessControl, constrainForJob:Job|null, allowServiceItemGuid:ApiGuid|undefined, getByGuid:(serviceItemGuid:ApiGuid) => ServiceItem|undefined, options: AccessControlOptions )
{
    return (serviceItem:ServiceItem, index:number, jobList:ServiceItem[]) => {

        if(allowServiceItemGuid === serviceItem.guid) //if specific job was allowed, return true now
            return true; 

        //todo: pass in getByGuid, and canEmployeeAccessJob will be able to recurse up the parent tree optionally if job.applyParentAccessControl is true
        if(canEmployeeAndJobAccessServiceItem(constrainForEmployee, constrainForJob?.guid ?? null, serviceItem, options))
        {
            return true;
        }
        return false;
    } 
}


export function constrainServiceItemsByManagerOrManagedEmployees(manager:Employee, employeeList: Employee[], options: AccessControlOptions)
{
    return (serviceItem:ServiceItem) => {
        return canManagerOrManagersEmployeesAccessServiceItem(manager, employeeList, serviceItem, options);
    }
}

//returns true if the manager OR any employee in the list can access the job (is assigned or not blocked)
// all it takes is one to be allowed, then it will be good.
export function canManagerOrManagersEmployeesAccessServiceItem(manager:Employee, employeeList: Employee[], serviceItem:ServiceItemForAccessControl, options: AccessControlOptions):boolean
{
    if(canEmployeeAccessServiceItem(manager, serviceItem, options))
        return true;
    
    for(let i=0; i < employeeList.length; i++)
    {
        if(canEmployeeAccessServiceItem(employeeList[i], serviceItem, options))
            return true;            
    }

    return false;
}

export function canManagerOrManagersEmployeesAndJobAccessServiceItem(manager:Employee, employeeList: Employee[], jobGuid:ApiGuid|null, serviceItem:ServiceItemForAccessControl, options: AccessControlOptions):boolean
{
    if(canManagerOrManagersEmployeesAccessServiceItem(manager, employeeList, serviceItem, options))
    {
        return canJobGuidAccessServiceItem(jobGuid, serviceItem, options);
    }
    return false;
}

