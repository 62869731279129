import { useCallback } from 'react';
import { ReactQueryCommonUseQueryOptions, VQueryKey } from "../../../sharedReact/src/hooks/queryHookHelpers";
import { ApiGuid, GetThingListOptions, Group, GroupForCreate, GroupForUpdate, GroupQueryOptions } from '../../../vericlock_api/src/types';
import { useQueryClient, UseQueryOptions } from 'react-query';
import { BaseUseThingListProps, ThingListActiveInactiveFetchOption, ThingListDeletedFetchOptions, useThingList, UseThingListProps } from '../../../sharedReact/src/hooks/useThingList';
import { useVeriClockApi } from '../../../sharedReact/src/hooks/ApiProviderCommon';
import { QueryCoreDataResponse } from "../../../vericlock_api/src/types/Company";
// import { employeeCleanupQueryKeys } from './EmployeeHooks';

// WARNING: the cached version of the current employee does not come from node, it's the PHP object
// which has some differences.  When in doubt, skip the cache and fetch directly from node with
// useCache = false
export function useGroup(groupGuid:ApiGuid|null|undefined)
{
    // console.warn('note - this is loading all active/inactive/deleted THEN deleted...odd');
    const gMain = useGroupList({
        statusToFetch: ['active','inactive'], 
        enabled: groupGuid != null  
    });

    //check main list first
    let group = groupGuid ? gMain.getByGuid(groupGuid) : undefined;
    // let group = gMain.groups.find(g => g.guid === groupGuid);    
    //flag to load deleted items, if job isn't found AFTER the list is queried
    const lookInTheTrash = groupGuid ? (gMain.isFetched && !group) : false;

    //only load/check the deleted jobs, if the activeInactive loaded and it wasn't in there.
    const gDel = useGroupList({
        statusToFetch: ['deleted'], 
        enabled: lookInTheTrash  
    });

    if(lookInTheTrash)
    {
        group = gDel.groups.find(g => g.guid === groupGuid);        
    }

    //combined isLoading
    const isLoading = groupGuid ? (gMain.isLoading || lookInTheTrash && gDel.isLoading) : false;
    //combined loadError
    let loadError = null;
    if(groupGuid)
    {
        const errs:string[] = [];
        if(gMain.loadError)
            errs.push(gMain.loadError)
        if(lookInTheTrash && gDel.loadError)
            errs.push(gDel.loadError);
        if(errs.length)
            loadError = errs.join(','); 
    }

    return {
        group,
        isLoading,
        isFetched:gMain.isFetched,
        loadError,
        update:gMain.update,
        create:gMain.create
    }
}

const commonUseQueryOptionsGroup:UseQueryOptions<Group[]> = {
    ...ReactQueryCommonUseQueryOptions
}
//NOTE by making these constants, the queries are safely cached - RQ treats array order as a unique key, so 'a','b' != 'b','a'

export type UseGroupListProps = BaseUseThingListProps;

//outside to ensure constant refs - otherwise the various internal things in useThingList re-render
const activeInactiveQueryKey:UseThingListProps<Group>['activeInactiveQueryKey'] = [VQueryKey.GroupList, ThingListActiveInactiveFetchOption, false];
const deletedQueryKey:UseThingListProps<Group>['deletedQueryKey'] = [VQueryKey.GroupList, ThingListDeletedFetchOptions,false];
const activeInactiveQueryKeyTeamView:UseThingListProps<Group>['activeInactiveQueryKey'] = [VQueryKey.GroupList, ThingListActiveInactiveFetchOption,true];
const deletedQueryKeyTeamView:UseThingListProps<Group>['deletedQueryKey'] = [VQueryKey.GroupList, ThingListDeletedFetchOptions,true];

function updateCoreDataWithGroups(queryClient:ReturnType<typeof useQueryClient>, groups:Group[])
{
    const coreData = queryClient.getQueryData<QueryCoreDataResponse>(VQueryKey.CoreData);
    if(!coreData)
        return;
    
    const newGroups = [...coreData.userGroups];
    groups.forEach(g => {
        const groupAtIndex = newGroups.findIndex(gg => gg.guid === g.guid);
        if(groupAtIndex !== -1)
        {
            newGroups[groupAtIndex] = g;
        }
        else
        {
            newGroups.push(g);
        }
    });

    queryClient.setQueryData(VQueryKey.CoreData, {
        ...coreData,
        userGroups: newGroups
    });
}
export function useGroupList(props:UseGroupListProps)
{
    const { api } = useVeriClockApi();
    const queryClient = useQueryClient();
    const fetchListCallback = useCallback(async (options:GetThingListOptions) => {
        const searchOptions:GroupQueryOptions = 
        {
            status: options.status,
            includeListOfManagers: true,
            teamView: options.teamView      
        }
    
        let { payload } = await api.queryGroups(searchOptions);
        return payload;
    },[api]);

    const groupUseThingListResp = useThingList({
        ...props,
        fetchListCallback,
        commonUseQueryOptions: commonUseQueryOptionsGroup,
        activeInactiveQueryKey: props.teamView ? activeInactiveQueryKeyTeamView : activeInactiveQueryKey,
        deletedQueryKey: props.teamView ? deletedQueryKeyTeamView : deletedQueryKey,
        type: 'group'  
    });
    const { updateCache, removeFromCache, getByGuid, things, ...restUseThingsResp } = groupUseThingListResp; //.updateCache;

    // const createUpdateList = useCallback(async (employees:(GroupForCreate|GroupForUpdate)[], oldEmployeesMap:Record<ApiGuid, Employee>) => {
    //     const r = await v.createUpdateGroupList(employees);
    //     updateCache(r, oldEmployeesMap);
    //     return r;
    // },[v,updateCache]);

    const update = useCallback(async (group:GroupForUpdate, old:Group) => {

        if(group.status === 'deleted')
        {
            //group update to delete == ACTUAL delete!
            await api.deleteGroup(group);
            removeFromCache([old]); //cull the old group

            queryClient.invalidateQueries(VQueryKey.EmployeeList);
            const coreData = queryClient.getQueryData<QueryCoreDataResponse>(VQueryKey.CoreData);
            if(coreData)
            {
                const groupAtIndex = coreData.userGroups.findIndex(g => g.guid === group.guid);
                if(groupAtIndex !== -1)
                {
                    //copy array and remove the group at the index
                    const newGroups = [...coreData.userGroups];
                    newGroups.splice(groupAtIndex,1);
                    queryClient.setQueryData(VQueryKey.CoreData, {
                        ...coreData,
                        userGroups: newGroups
                    });
                }
            }
            return;
        }
        
        const updatedGroup = (await api.updateGroup(group)).payload;
        updateCache([updatedGroup], { [old.guid]: old });

        updateCoreDataWithGroups(queryClient, [updatedGroup]);
        return updatedGroup;        
    },[api, updateCache,removeFromCache,queryClient]);
    
    const createUpdateList = useCallback(async (groups:(GroupForCreate|GroupForUpdate)[], oldGroupsMap:Record<ApiGuid, Group>) => {
        const r = await api.createUpdateGroupList(groups);
        updateCache(r.payload.groups, oldGroupsMap);
        updateCoreDataWithGroups(queryClient, r.payload.groups);
        return r;
    },[api,updateCache, queryClient]);

    const create = useCallback(async (group:GroupForCreate) => {        
        let createdGroup = (await api.createGroup(group)).payload;
        updateCache([createdGroup], {}); //empty map, signals JUST a create
        updateCoreDataWithGroups(queryClient, [createdGroup]);
        return createdGroup;        
    },[api, updateCache, queryClient]);


    return {
        ...restUseThingsResp,
        groups: things,
        // createUpdateList,
        update,
        create,
        createUpdateList,
        getByGuid
    }
}

