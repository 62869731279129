import * as React from 'react';
import * as ApiType from '../../../../../../vericlock_api/src/types';

import './ItemLabel.css';
import { useJob } from '../../../../hooks/JobHooks';
import { useEmployee } from '../../../../hooks/EmployeeHooks';
import { useTimeType } from '../../../../hooks/TimeTypeHooks';
import { useServiceItem } from '../../../../hooks/ServiceItemHooks';
import { useGroup } from '../../../../hooks/GroupHooks';
import { usePayrollItem } from '../../../../hooks/PayrollItemHooks';
import { usePtoTypes } from '../../../../hooks/PtoHooks';

export function ItemLabelList(props:{
    limit:number|null,
    employeeGuids?: ApiType.ApiGuid[],
    groupGuids?: ApiType.ApiGuid[],
    jobGuids?: ApiType.ApiGuid[],
    serviceItemGuids?: ApiType.ApiGuid[],
    employeeNotFoundText?:string,
    groupNotFoundText?:string
    noJ?:boolean
})
{
    let somethingToRender = (props.employeeGuids && props.employeeGuids.length > 0) || 
        (props.groupGuids && props.groupGuids.length > 0) ||
        (props.jobGuids && props.jobGuids.length > 0) ||
        (props.serviceItemGuids && props.serviceItemGuids.length > 0);

    if(!somethingToRender)
        return <></>;
    
    let { noJ=false } = props; //destructor some defaults 

    let eeListJsx = [];
    let gListJsx = [];
    let jListJsx = [];
    let siListJsx = [];
    if(props.groupGuids)
    {
        //render up to list size OR limit
        for(let i=0; i < props.groupGuids.length; i++)
        { 
            let guid = props.groupGuids[i];
            gListJsx.push(<VGroupLabel key={guid} guid={guid} notFoundText={props.groupNotFoundText}/>);

            if(props.limit && i == props.limit - 1)
            {
                //limit hit
                let howManyMore = props.groupGuids.length - i - 1;
                if(howManyMore)
                {
                    gListJsx.push(<GroupLabel key={'g_list_no_guid_more'}>More <span className="badge">{howManyMore}</span></GroupLabel>);
                }
                break;
            }
        } 
    }
    if(props.employeeGuids)
    {
        //render up to list size OR limit
        for(let i=0; i < props.employeeGuids.length; i++)
        { 
            let guid = props.employeeGuids[i];
            eeListJsx.push(<VEmployeeLabel key={guid} guid={guid} id={true} notFoundText={props.employeeNotFoundText}/>);

            if(props.limit && i == props.limit - 1)
            {
                //limit hit
                let howManyMore = props.employeeGuids.length - i - 1;
                if(howManyMore)
                {
                    eeListJsx.push(<EmployeeLabel key={'ee_list_no_guid_more'}>More <span className="badge">{howManyMore}</span></EmployeeLabel>);
                }
                break;
            }
        } 
    }
    if(props.jobGuids)
    {
        //render up to list size OR limit
        for(let i=0; i < props.jobGuids.length; i++)
        { 
            let guid = props.jobGuids[i];
            jListJsx.push(<VJobLabel noJ={noJ} key={guid} guid={guid} code={true}/>);

            if(props.limit && i == props.limit - 1)
            {
                //limit hit
                let howManyMore = props.jobGuids.length - i - 1;
                if(howManyMore)
                {
                    jListJsx.push(<JobLabel key={'j_list_no_guid_more'}>More <span className="badge">{howManyMore}</span></JobLabel>);
                }
                break;
            }
        } 
    }
    if(props.serviceItemGuids)
    {
        //render up to list size OR limit
        for(let i=0; i < props.serviceItemGuids.length; i++)
        { 
            let guid = props.serviceItemGuids[i];
            siListJsx.push(<VServiceItemLabel key={guid} guid={guid} code={true}/>);

            if(props.limit && i == props.limit - 1)
            {
                //limit hit
                let howManyMore = props.serviceItemGuids.length - i - 1;
                if(howManyMore)
                {
                    siListJsx.push(<ServiceItemLabel key={'si_list_no_guid_more'}>More <span className="badge">{howManyMore}</span></ServiceItemLabel>);
                }
                break;
            }
        } 
    }    
    let jsx = [];
    if(gListJsx.length)
        jsx.push(gListJsx);
    if(eeListJsx.length)
        jsx.push(eeListJsx);
    if(jListJsx.length)
        jsx.push(jListJsx);
    if(siListJsx.length)
        jsx.push(siListJsx);
       
    return <span>{jsx.map( (l,num) => <div key={num}>{l}</div>)}</span>;
    // return <>{gListJsx}{(gListJsx.length > 0 && eeListJsx.length > 0 ) && <br/>}{eeListJsx}</>;
}

function VEmployeeLabel_Internal({
    idNameSeparator='|',
    ...props
}:{
    guid:ApiType.ApiGuid,
    id?:boolean,
    notFoundText?:string
    style?:React.CSSProperties,
    noStatusSuffix?:boolean,
    idNameSeparator?:React.ReactNode
    noStyle?:boolean //default false - ie, use bs styling
    // nameStyle?:"barf"|"firstLast"|"lastFirst"|"FirstL"
})
{
    const { employee, isLoading, loadError } = useEmployee(props.guid);
    const showSuffix = !props.noStatusSuffix;
    if(isLoading)
        return <></>;
    if(loadError)
        return <>Error Loading EE</>

    if(employee)
    {
        let nameJsx = props.id ? <>{employee.phoneID} {idNameSeparator} {employee.fullName}</>
                        : <>{employee.fullName}</>;

        if(props.noStyle)
            return nameJsx;
        return(
        <EmployeeLabel style={props.style}>
            {nameJsx}{showSuffix && statusSuffix[employee.status]}
        </EmployeeLabel>
        );
    }

    if(props.notFoundText)
    {
        if(props.noStyle)
            return <>{props.notFoundText}</>;
        return <EmployeeLabel>{props.notFoundText}</EmployeeLabel>
    }

    return(<>Employee Not Found</>); //todo: consider 'Other Employee' - almost always a permission issue here
}
export const VEmployeeLabel = React.memo(VEmployeeLabel_Internal);
interface EmployeeLabelProps 
{
    style?:React.CSSProperties
    // children?: React.ReactNode
}
//SFC - stateless functional component - injects the children prop into the mix with EmployeeLabelProps
const EmployeeLabel:React.FC<React.PropsWithChildren<EmployeeLabelProps>> = ({style, children}) => 
{
    return <span className="label label-default ItemLabel" style={style}>{children}</span>
}

function VGroupLabel_Internal(props:{
    guid:ApiType.ApiGuid,
    isPrimaryGroup?:boolean,
    notFoundText?:string,
    noG?:boolean,
    noStatusSuffix?:boolean
    noStyle?:boolean //default false - ie, use bs styling

})
{
    const { group, isLoading, loadError} = useGroup(props.guid);
    const gPrefix = !props.noG;
    const showSuffix = !props.noStatusSuffix;
    if(isLoading)
        return <></>;
    if(loadError)
        return <>Error Loading Group</>
    if(group)
    {
        let nameJsx = <>{group.name}</>;
        //use the employee label?  
        if(props.noStyle)
            return nameJsx;

        return(
        <GroupLabel isPrimaryGroup={props.isPrimaryGroup}>
            {gPrefix && <>G | </>}{nameJsx}{showSuffix && statusSuffix[group.status]}
        </GroupLabel>
        );
    }
    if(props.notFoundText)
    {
        return <GroupLabel>{props.notFoundText}</GroupLabel>;
    }

    return(<>Group Not Found</>);
}
export const VGroupLabel = React.memo(VGroupLabel_Internal);
//SFC - stateless functional component - injects the children prop into the mix with GroupLabelProps
const GroupLabel:React.FC<React.PropsWithChildren<{isPrimaryGroup?:boolean}>> = ({isPrimaryGroup, children}) => 
{
    if(isPrimaryGroup)
        return <span className="label label-success ItemLabel">{children}</span>    

    return <span className="label label-default ItemLabel">{children}</span>
}

const statusSuffix = {
    active: '',
    inactive: ' [i]',
    deleted: ' [d]',
}
function VJobLabel_Internal(props:{
    guid:ApiType.ApiGuid,
    code: boolean, 
    noJ?:boolean,
    noStatusSuffix?:boolean
    noStyle?:boolean //default false - ie, use bs styling
    jobNotFoundText?:string
})
{
    const { job, isLoading, loadError} = useJob(props.guid);
    const jPrefix = !props.noJ;
    const showSuffix = !props.noStatusSuffix;

    if(isLoading)
        return <></>;
    if(loadError)
        return <>Error Loading Job</>
    
    if(job)
    {
        let nameJsx = props.code ? 
            <>{job.code} - {job.name}</>
            : <>{job.name}</>;
        if(props.noStyle)
            return nameJsx;
        return(
        <JobLabel>
            {jPrefix && <>J | </>}{nameJsx}{showSuffix && statusSuffix[job.status]}
        </JobLabel>
        );
    }

    return(<>{props.jobNotFoundText??'Job Not Found'}</>);
}
export const VJobLabel = React.memo(VJobLabel_Internal);


const JobLabel:React.FC<React.PropsWithChildren> = ({children}) => 
{
    return <span className="label label-default ItemLabel">{children}</span>
}

function VPayrollItemLabel_Internal(props: {
    guid:ApiType.ApiGuid,
    noStatusSuffix?:boolean
    noStyle?:boolean
})
{
    const { payrollItem, isLoading, loadError } = usePayrollItem(props.guid);
    const showSuffix = !props.noStatusSuffix;

    if(isLoading)
        return <></>;
    if(loadError)
        return <>Error Loading Payroll Item</>
    if(payrollItem)
    {
        let nameJsx = <>{payrollItem.name}</>
        if(props.noStyle)
            return nameJsx;
        //use the employee label?  
        return(
        <PayrollItemLabel>
            {nameJsx}{showSuffix && statusSuffix[payrollItem.status]}
        </PayrollItemLabel>
        );
    }

    return(<>Payroll Item Not Found</>);
}
export const VPayrollItemLabel = React.memo(VPayrollItemLabel_Internal);
const PayrollItemLabel:React.FC<React.PropsWithChildren> = ({children}) => 
{
    return <span className="label label-default ItemLabel">{children}</span>
}

function VServiceItemLabel_Internal(props:{
    guid:ApiType.ApiGuid,
    code:boolean,
    noS?:boolean,
    noStatusSuffix?:boolean
    noStyle?:boolean
})
{
    const { serviceItem, isLoading, loadError } = useServiceItem(props.guid);
    const showSuffix = !props.noStatusSuffix;
    const sPrefix = !props.noS;

    if(isLoading)
        return <></>;
    if(loadError)
        return <>Error Loading Service Item</>
    if(serviceItem)
    {
        let nameJsx = props.code ? 
            <>{serviceItem.serviceItemCode} - {serviceItem.name}</>
            : <>{serviceItem.name}</>;

        if(props.noStyle)
            return nameJsx;
        //use the employee label?  
        return(
        <ServiceItemLabel>
            {sPrefix && <>S | </>}{nameJsx}{showSuffix && statusSuffix[serviceItem.status]}
        </ServiceItemLabel>
        );
    }

    return(<>Service Item Not Found</>);
}
export const VServiceItemLabel = React.memo(VServiceItemLabel_Internal);
//SFC - stateless functional component - injects the children prop into the mix with GroupLabelProps
const ServiceItemLabel:React.FC<React.PropsWithChildren> = ({children}) => 
{
    return <span className="label label-default ItemLabel">{children}</span>
}

function VPtoTypeLabel_Internal(props:{
    guid:ApiType.ApiGuid,
    noStatusSuffix?:boolean
    noStyle?:boolean
})
{
    const { getByGuid, isLoading, loadError } = usePtoTypes({statusToFetch:['active','inactive','deleted']});
    const showSuffix = !props.noStatusSuffix;

    if(isLoading)
        return <></>;
    if(loadError)
        return <>Error Loading S</>
    const ptoType = getByGuid(props.guid);
    if(ptoType)
    {
        let nameJsx = <>{ptoType.name}</>;

        if(props.noStyle)
            return nameJsx;
        //use the employee label?  
        return(
        <ItemLabel>
            S | {nameJsx}{showSuffix && statusSuffix[ptoType.status]}
        </ItemLabel>
        );
    }

    return(<>PTO Type Not Found</>);
}
export const VPtoTypeLabel = React.memo(VPtoTypeLabel_Internal);

const ItemLabel:React.FC<React.PropsWithChildren> = ({children}) =>
{    
    return <span className="label label-default ItemLabel">{children}</span>
}

export const VTimeTypeLabel:React.FC<{ timeTypeId: number }> = ({timeTypeId}) => 
{
    const timeType = useTimeType({ timeTypeId });
    if(timeType.isLoading)
        return <></>
    
    if(timeType.loadError)
        return <>Error Loading TimeType</>

    if(timeType.timeType)
        return <ItemLabel>{timeType.timeType.label}</ItemLabel>
    
    return <>Time type not found</>
}

export function PrimaryAdditionalGroupLabelList(props:{employee:ApiType.Employee})
{
    const e = props.employee;
    const primaryGroupJsx = e.groupGuid === null ? null :  <VGroupLabel isPrimaryGroup={true} noG={true} guid={e.groupGuid} notFoundText={"Other Group"} />
    const additionalGroupsJsx = e.additionalGroupGuids === null ? null : e.additionalGroupGuids.map(g => <VGroupLabel key={g} noG={true} guid={g} notFoundText={"Other Group"} />);
    return <>{primaryGroupJsx}{additionalGroupsJsx}</>
}