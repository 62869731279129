import _ from "lodash";
import { useCallback, useMemo } from "react";
import { QueryClient, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { ApiGuid } from "../../../vericlock_api/src/types/ApiGuid";
import { GetThingListOptions } from "../../../vericlock_api/src/types/Thing"; //todo: move this type out of VAppinterface probably
import { ThingStatus } from "../../../vericlock_api/src/types/Thing";
// import { useTraceUpdatePropsOnlyFC } from "./debugHooks";
import { VQueryKey, ReactQueryExtractErrorString } from "./queryHookHelpers";


export const ThingListActiveInactiveFetchOption:GetThingListOptions = { status: ["active", "inactive"] };
export const ThingListDeletedFetchOptions:GetThingListOptions = { status: ["deleted"] };

type AllowedThing = 'job'|'employee'|'group'|'serviceItem'|'payrollItem'|'contact'|'customField'|'ptoType';

interface Thing {
    guid: ApiGuid
    status: ThingStatus
}

export type BaseUseThingListProps = {
    statusToFetch:ThingStatus[],
    enabled?:boolean,
    teamView?:boolean
}
export type UseThingListProps<THING> = BaseUseThingListProps & {
    type: AllowedThing,
    commonUseQueryOptions: UseQueryOptions<THING[]>,
    fetchListCallback:(options:GetThingListOptions) => Promise<THING[]>
    activeInactiveQueryKey: [VQueryKey,GetThingListOptions, boolean]
    deletedQueryKey: [VQueryKey,GetThingListOptions, boolean]
}


export function useThingList<D extends Thing>(props:UseThingListProps<D>) 
{
    const queryClient = useQueryClient();
    const { enabled=true } = props; 
    //fetch active + inactive together
    //fetch deleted    
    //update/create can move to/inject into the correct cache 
    //filter on the output of this hook
    const fetchActiveInactive = enabled === true && props.statusToFetch.findIndex(a => a === 'active' || a === 'inactive') >= 0;
    const fetchDeleted = enabled === true && props.statusToFetch.indexOf('deleted') >= 0;

    //we may want to query the 3 lists separately, to make fetching data simpler/updating/mutating?
    const activeInactiveQuery = useQuery<D[]>(props.activeInactiveQueryKey, () => props.fetchListCallback({...ThingListActiveInactiveFetchOption, teamView: props.teamView }), {
        enabled: fetchActiveInactive,
        ...props.commonUseQueryOptions
    });
    const deletedQuery = useQuery<D[]>(props.deletedQueryKey, () => props.fetchListCallback({...ThingListDeletedFetchOptions, teamView: props.teamView }), {
        enabled: fetchDeleted,
        ...props.commonUseQueryOptions
    });

    //create combined isFetched flag
    const isFetched = (activeInactiveQuery.isFetched || !fetchActiveInactive) && (deletedQuery.isFetched || !fetchDeleted)

    const removeFromCache = useCallback( (oldThings:D[]) => {
        for(let i=0; i < oldThings.length; i++)
        {
            let oldThing = oldThings[i];
            const queryKey = oldThing.status === 'active' || oldThing.status === 'inactive' ? props.activeInactiveQueryKey : props.deletedQueryKey;        
            const oldList = queryClient.getQueryData<D[]>(queryKey); 
            if(oldList)
            {
                const indexToRemove = oldList.findIndex(t => t.guid === oldThing.guid);
                if(indexToRemove === -1)
                    throw new Error('removeFromCache did not find item in list ['+oldThing.guid+']');
                const newList = [...oldList];
                newList.splice(indexToRemove,1); //remove from the appropriate slot
                queryClient.setQueryData<D[]>(queryKey, newList); //update the cache
            }
        }
        
    },[props.activeInactiveQueryKey,props.deletedQueryKey,queryClient]);

    const replaceCache = useCallback( (newOrUpdatedThings:D[], type: 'activeInactive'|'deleted') => {
        const newQueryKey = type === 'activeInactive' ? props.activeInactiveQueryKey : props.deletedQueryKey;

        queryClient.setQueryData<D[]>(newQueryKey, newOrUpdatedThings); //update the cache
    },[props.activeInactiveQueryKey, props.deletedQueryKey, queryClient]);

    const updateCache = useCallback( (newOrUpdatedThings:D[], oldThingMap:Record<ApiGuid, D>) => {

        //wrap below if perf is an issue
        // notifyManager.batch(() => {

        // });
        for(let i=0; i < newOrUpdatedThings.length; i++)
        {
            const newThing = newOrUpdatedThings[i];
            const oldThing = oldThingMap[newThing.guid];

            const newQueryKey = newThing.status === 'active' || newThing.status === 'inactive' ? props.activeInactiveQueryKey : props.deletedQueryKey;
            
            if(oldThing)
            {
                const oldQueryKey = oldThing.status === 'active' || oldThing.status === 'inactive' ? props.activeInactiveQueryKey : props.deletedQueryKey;

                //look to update job in the same list it came from (if it was retrieved via this hook/query)
                const oldLocationList = queryClient.getQueryData<D[]>(oldQueryKey); 
                if(oldLocationList)
                {
                    const newThings = [...oldLocationList];
                    const indexToReplace = newThings.findIndex(t => t.guid === newThing.guid);
                    if(indexToReplace === -1)
                    {
                        //[0] is the VQueryKey entry - for dbg purposes
                        const msg = `update(${props.activeInactiveQueryKey[0]}) - success, but during update could not find item in the local copy - very unusual - updating a deleted item using the wrong hook?`;
                        console.error(msg)
                        throw new Error(msg);
                    }
                    if(oldQueryKey === newQueryKey) //update did not change list it is in
                        newThings[indexToReplace] = newThing;
                    else //it has changed lists
                    {
                        newThings.splice(indexToReplace,1); //remove the element from its old list - it is updated below)
                        
                        //inject into its new location, if it is present
                        const newLocationThings = queryClient.getQueryData<D[]>(newQueryKey); 
                        if(newLocationThings) 
                        {
                            const alreadyThere = newLocationThings.findIndex(j => j.guid === newThing.guid);
                            if(alreadyThere === -1)
                            {
                                const setNewThings = [...newLocationThings, newThing];
                                queryClient.setQueryData<D[]>(newQueryKey, setNewThings); //update the cache
                            }   
                            else {
                                throw new Error(`updating ${props.activeInactiveQueryKey[0]}, new status already exists in that table! shouldnt be possible`); //throwing is maybe harsh...clientError reporting would be handy and then ignore
                            }
                    
                        }
                        //else - possible never loaded - like if we are on active list and delete job, deleted list not loaded yet, nothing to patch
                    }
                    queryClient.setQueryData<D[]>(oldQueryKey, newThings); //update the cache
                }
                // this CAN happen now, when something occurs to upate an object that we are cache-updating via this,
                // from outside the normal hook use, and the data has not been loaded yet.
                // so just continue on.
                // else { 
                //     //really - this shouldn't happen
                //     const msg = 'updateThirdPartyContact - success, but the contact list is empty in the cache - this should not happen';
                //     console.error(msg)
                //     throw new Error(msg);
                //     // queryClient.setQueryData<ThirdPartyContact[]>(QueryKeyThirdPartyContacts, [updatedContact]); //update the cache
                // }

                if(oldThing.status !== newThing.status && newThing.status === 'deleted')
                    invalidateQueriesForDeletedThings(props.type, queryClient);
            }
            else //create, not update
            {
                const newThingList = queryClient.getQueryData<D[]>(newQueryKey); 
                if(newThingList)
                {
                    queryClient.setQueryData<D[]>(props.activeInactiveQueryKey, [...newThingList, newThing])
                }        
            }
        }
    },[queryClient, props.activeInactiveQueryKey, props.deletedQueryKey, props.type]);
    // useTraceUpdatePropsOnlyFC('updateCache: useCallback', [queryClient, props.activeInactiveQueryKey, props.deletedQueryKey]);

    let isLoading = activeInactiveQuery.isLoading && fetchActiveInactive || deletedQuery.isLoading && fetchDeleted;
    let isFetching = activeInactiveQuery.isFetching && fetchActiveInactive || deletedQuery.isFetching && fetchDeleted;
    let loadErrorActiveInactive = fetchActiveInactive &&  ReactQueryExtractErrorString(props.activeInactiveQueryKey[0], activeInactiveQuery);
    let loadErrorDeleted  = fetchDeleted && ReactQueryExtractErrorString(props.deletedQueryKey[0], deletedQuery);
    let loadError = loadErrorActiveInactive && loadErrorActiveInactive.isError && loadErrorDeleted && loadErrorDeleted.isError ? 
                    loadErrorActiveInactive.errorString + ', ' + loadErrorDeleted.errorString
                    : loadErrorActiveInactive && loadErrorActiveInactive.isError ? loadErrorActiveInactive.errorString
                         : loadErrorDeleted && loadErrorDeleted.isError ? loadErrorDeleted.errorString : null;
    
    const active = props.statusToFetch.findIndex(a => a === 'active') >= 0;
    const inactive = props.statusToFetch.findIndex(a => a === 'inactive') >= 0;
    const deleted = props.statusToFetch.findIndex(a => a === 'deleted') >= 0;
    const things = useMemo(() => {
        // let x = _.memoize( () => {
            // console.log('useMemo in useThingList');
            if(isLoading || loadError)
                return []; //not ready/invalid

            const statusMap = { active, inactive, deleted };

            const thingList:D[] = [];
            if((statusMap.active || statusMap.inactive) && activeInactiveQuery.data) {
                activeInactiveQuery.data.forEach(j => {
                    if(statusMap[j.status])
                        thingList.push(j);
                });
            }
            if(statusMap.deleted && deletedQuery.data) {
                deletedQuery.data.forEach(j => {
                    if(statusMap[j.status])
                        thingList.push(j);
                });
            }
            return thingList;
        // });
        // return x();
    },[activeInactiveQuery.data, deletedQuery.data, isLoading, loadError, active,inactive,deleted]);

    const getByGuid = useMemo(() => {
        
        const thingByGuidMap:Record<ApiGuid, D> = {};
        things.forEach(t => thingByGuidMap[t.guid] = t);

        return (guid:ApiGuid):D|undefined => {
            return thingByGuidMap[guid] 
        }
    },[things]);
    //useTraceUpdatePropsOnlyFC('useThingList: useMemo', [activeInactiveQuery.data, deletedQuery.data, isLoading, loadError, active,inactive,deleted]);
    //beware - isLoading treatment above could create a flick if isLoading is true on refetch (doubful it is)
    return { 
        things, 
        isLoading,
        isFetched,
        isFetching,
        loadError,
        // isIdle: query.isIdle,
        updateCache,
        removeFromCache,
        replaceCache,
        getByGuid       
    }
}

const invalidationDependencies:Record<AllowedThing, VQueryKey[]> = {
    'job': [ 
        VQueryKey.JobRules,
        VQueryKey.ServiceItemRules,
        VQueryKey.PayrollItemRules,
        VQueryKey.JobIntegrationMaps,
    ],
    'employee': [
        VQueryKey.JobRules,
        VQueryKey.ServiceItemRules,
        VQueryKey.PayrollItemRules,
        VQueryKey.EmployeeIntegrationMaps,
    ],
    'group': [
        VQueryKey.JobRules,
        VQueryKey.ServiceItemRules,
        VQueryKey.PayrollItemRules,
    ],
    'serviceItem': [
        VQueryKey.ServiceItemRules,
        VQueryKey.PayrollItemRules,
        VQueryKey.ServiceItemIntegrationMaps,
    ],
    'payrollItem': [
        VQueryKey.PayrollItemRules,
        VQueryKey.PayrollItemIntegrationMaps,
    ],
    'contact': [], 
    'customField': [],
    'ptoType': [],
}

function invalidateQueriesForDeletedThings(type:AllowedThing, queryClient:QueryClient)
{
    const deps = invalidationDependencies[type];
    deps.forEach(queryKey => queryClient.invalidateQueries(queryKey, {
        refetchActive: true, 
        // refetchInactive: true      
    }));
}