// import { useState } from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
    QueryClient,
    // useMutation,
    UseQueryOptions,
    // useMutation,
    // useQueryClient,
    // QueryClient,
    // QueryClientProvider,
  } from 'react-query'
import { ApiGuid, GetThingListOptions, ThirdPartyContact, ThirdPartyContactAllOptionalForCreate, ThirdPartyContactAllOptionalForUpdate } from '../../../vericlock_api/src/types';
import { ReactQueryCommonUseQueryOptions, VQueryKey } from '../../../sharedReact/src/hooks/queryHookHelpers';
import { BaseUseThingListProps, ThingListActiveInactiveFetchOption, ThingListDeletedFetchOptions, useThingList, UseThingListProps } from '../../../sharedReact/src/hooks/useThingList';

import { useVeriClockApi } from '../../../sharedReact/src/hooks/ApiProvider';


export function useThirdPartyContact(contactGuid:ApiGuid|undefined|null)
{
    const [contact, setContact] = useState<null|ThirdPartyContact>(null);

    const { isLoading, loadError, contacts, update, create } = useThirdPartyContactList({
        statusToFetch: ['active'],
        enabled: contactGuid ? true : false //passing null will not start the fetch - no point
    });   //for now, lets just get the list - our current use case is in the edit/create form, launched from the job edit form where the list is loaded...so this is a no brainer for now...and probably never will be an issue
    
    // const v = useV();
    //effect to set the local state variable upon the list hook returning a new list
    useEffect(() => {
        // maybe need to pass contact as a dependency and check if
        // if(contact && contact.guid !== contactGuid) - to avoid more renders? unsure
        if(contacts)
        {
            if(contactGuid) {
                let found = contacts.find(c => c.guid === contactGuid);
                if(found) {
                    return setContact(found);                    
                }
                //fall through                
            }
            //fall trhough to here, either contactGuid is null, or it wasn't found
            return setContact(null);
        }
    },[contacts,contactGuid]);
    //todo - if a contact is deleted, it won't be in the list... we could then engage the individual query, with a 'look for deleted flag' on,
    // const query = useQuery(['thirdPartyContact', contactGuid], () => v.getThirdPartyContact(contactGuid),{
        // enabled: tryIndividualContactFetch
    // });
    // via a state var

    // via the 'enabled:true' option, 
    // const v = useV();
    // return { 
    //     contact:query.data, 
    //     isLoading: query.isLoading, 
    //     loadError: query.isError ? query.error : null 
    // }

    return {
        contact,
        isLoading,
        loadError,
        update,
        create
    }
}

export type UseThirdPartyContactListProps = BaseUseThingListProps;
const commonUseQueryOptionsContact:UseQueryOptions<ThirdPartyContact[]> = {
    ...ReactQueryCommonUseQueryOptions
}
const activeInactiveQueryKey:UseThingListProps<ThirdPartyContact>['activeInactiveQueryKey'] = [VQueryKey.ThirdPartyContacts, ThingListActiveInactiveFetchOption, false];
const deletedQueryKey:UseThingListProps<ThirdPartyContact>['deletedQueryKey'] = [VQueryKey.ThirdPartyContacts, ThingListDeletedFetchOptions, false];
const activeInactiveQueryKeyTeamView:UseThingListProps<ThirdPartyContact>['activeInactiveQueryKey'] = [VQueryKey.ThirdPartyContacts, ThingListActiveInactiveFetchOption, true];
const deletedQueryKeyTeamView:UseThingListProps<ThirdPartyContact>['deletedQueryKey'] = [VQueryKey.ThirdPartyContacts, ThingListDeletedFetchOptions, true];

export function invalidateQueriesFromJobUpdate(queryClient:QueryClient) //this makes stale all contacts loaded, and they will be reloaded next time they are needed - as the job update/create potentially created a new contact
{
    queryClient.invalidateQueries(activeInactiveQueryKey);
}
export function useThirdPartyContactList(props:UseThirdPartyContactListProps)
{
    const { api } = useVeriClockApi(); 
    const fetchListCallback = useCallback(async (options:GetThingListOptions) => {
        return (await api.getThirdPartyContactList(options.status)).payload.tpContacts
    },[api]);
    
    const useThingListResponse = useThingList({
        ...props,
        fetchListCallback,
        commonUseQueryOptions: commonUseQueryOptionsContact,
        activeInactiveQueryKey: props.teamView ? activeInactiveQueryKeyTeamView : activeInactiveQueryKey,
        deletedQueryKey: props.teamView ? deletedQueryKeyTeamView : deletedQueryKey,
        type: 'contact' 
    });
    const { updateCache, things, ...restUseThingsResp } = useThingListResponse; //.updateCache;

    const update = useCallback(async (contact:ThirdPartyContactAllOptionalForUpdate, old:ThirdPartyContact) => {
        let updatedContact = (await api.updateThirdPartyContact(contact)).payload.tpContact
        updateCache([updatedContact], { [old.guid]: old });
        return updatedContact;        
    },[api, updateCache]);

    const create = useCallback(async (contact:ThirdPartyContactAllOptionalForCreate) => {
        const newContact = (await api.createThirdPartyContact(contact)).payload.tpContact
        updateCache([newContact], {}); //empty map, signals JUST a create
        return newContact;        
    },[api, updateCache]);


    return {
        ...restUseThingsResp,
        contacts: things,
        // createUpdateList,
        update,
        create
    }
}