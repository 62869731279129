import { useCallback, useMemo } from 'react';
import { EmployeePermission } from '../../../vericlock_api/src/types/EmployeePermission';

import { useCompanyLoaded } from './companyHooks';
import { useManagers } from './ManagerHooks';
import { useAppContext } from '../app/AppContextProvider';

//duped in client.ts - unify
function _signalVeriClockInfoHeader(res: Response)
{
    try {
        let data = res.headers.get('vericlock_info');
        let showFlair = false;
        if(data)
        {
            let decodedObj = JSON.parse(atob(decodeURIComponent(data)));
            if(decodedObj)
            {
                if(decodedObj.n == 1)
                {
                    showFlair = true;
                }
            }
        }
        _showNewNotificationFlair(showFlair);
        
    } catch (err)
    {
        console.log("Error trying to check vericlock_info return object");
        console.log(err);
    }
}
function _showNewNotificationFlair(showFlair:boolean)
{
    let e = document.getElementsByClassName('newNotificationsFlair');
    for(let i=0; i < e.length; i++)
    {
        let elem = e[i] as HTMLElement;
        elem.style.display = showFlair ? "inline-block" : "none";
    }
}

export function useV()
{
    const appContext = useAppContext();
    const { company, user, isAdminMode } = appContext.coreData;
    const newV = useMemo(() => {
        const v = {   
            getTimezone: () => company.settings.timezone,
            isAuthenticated: () => !!user, //user defined - we are authenticated - always true at the moment
            getWeekStart: () => company.settings.payrollSettings.weekStart,
            getUser: () => user,                        // Comes from employeeApiTypescriptCompatibleOutput() in PHP
            signalVeriClockInfoHeader: (res: Response) => _signalVeriClockInfoHeader(res),
            showNewNotificationFlair: (showFlair: boolean) => _showNewNotificationFlair(showFlair),
            isAdminMode:!!isAdminMode,
        }
        return v;
    },[company, user, isAdminMode]);
    return newV; 
}

type AllowedPermissions = EmployeePermission;
export const hack:EmployeePermission|undefined = undefined;
export function usePermissions()
{
    const companyMethods = useCompanyLoaded();
    const managers = useManagers();
    const user = useV().getUser();
    const company = companyMethods.company; //if not loaded, they don't get permission - could result in a flash here or there...maybe suboptimal?
    let isManagerActual = false;
    if(!managers.isLoading && !managers.loadError) {
        isManagerActual = managers.isManager(user);
    }
    const hasPermission = useCallback( (userPermission:AllowedPermissions, options?: {
        ifManagerMustBeTrue?: boolean   //if user is a manager, this must be true (if set) - can stack conditions more cleanly
    }) => {
        const opt = {
            ifManagerMustBeTrue: true,
            ...options
        };
        const ifManagerMustBeTrue = opt.ifManagerMustBeTrue;
        let isManager = isManagerActual && ifManagerMustBeTrue; //if not set, then we assume this is true, but if it is set, then this can trigger the manager being false and the permission in that case false
        //permissions that are not automatically true for admins
        switch(userPermission)
        {
            case 'can_build_custom_reports':
                if(company.settings.customConfig.canBuildCustomReports)
                {
                    return user.type === 'admin';
                }
                return false;
            //if the feature flag is off, no one can see/do it
            case 'can_edit_group_custom_settings':   
            case 'can_edit_employee_custom_settings':
                if(!company.settings.customConfig.featureRoleBasedSettingsEnabled)
                    return false;
                return user.type === 'admin';// || isManager;
        }

        //admins can do anything (if they can't, permission checked above)
        if(user.type === 'admin')
            return true;

        switch(userPermission)
        {
            case 'can_view_job_details':
                return user.settings.permissions.canViewJobDetails;
            case 'can_view_service_item_details':
                return user.settings.permissions.canViewServiceItemDetails;
            case 'can_oveerride_required_fields_on_timesheet_edit':
                if(company?.settings.permissions.managersCanSkipRequiredFieldsOnTimesheetEdit)
                    return isManager; 
                return false;
            case 'can_administer_kiosks':
                return false; //non-admin at the moment
            case 'can_edit_employees':
                if(company?.settings.permissions.managersCanEditEmployees)
                    return isManager;
                return false;       
            case 'can_access_locate_user_button':
            case 'can_view_clock_event_history':
                return isManager;     
            case 'can_edit_jobs':
                if(company?.settings.permissions.managersCanEditJobs)
                    return isManager;
                return false; //only admins can edit jobs in this account
            case 'can_edit_service_items':
                if(company?.settings.permissions.managersCanEditServiceItems)
                    return isManager;
                return false; //only admins can edit jobs in this account
            case 'can_edit_pto_record':
                if(company?.settings.permissions.managersCanApprovePTO)       
                    return isManager;     
                return false;
            case 'can_edit_clock_events_of_others':
                if(company?.settings.timeSettings.managersCanEditTimesheets)
                    return isManager;
                return false;
            case 'can_edit_clock_events_of_self':
                    return user.settings.permissions.canEditOwnTimeSheet;
            case 'can_approve_clock_events':
                if(company?.settings.timeSettings.managersCanApproveTimesheets)
                    return isManager;
                return false;

            case 'can_view_audit_trail':
                if(company?.settings.permissions.managersCanViewAuditTrail)
                    return isManager;
                return false;
            case 'can_view_full_audit_trail':
                if(company?.settings.permissions.managersCanViewFullAuditTrail)
                    return isManager;
                return false;
            case 'can_edit_own_clock_events':
                return user.settings.permissions.canEditOwnTimeSheet;
            case 'can_view_own_clock_events':
                return user.settings.permissions.canViewOwnTimeSheet;
            
/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
//  Any Manager
/////////////////////////////////////////////////////////////////////////////////////////////
            case 'can_update_device_info':
            case 'can_edit_schedule_shifts':
            case 'can_edit_shift_conflicts':
            case 'can_edit_schedule_templates':
                if(company.settings.permissions.managersCanEditSchedules)
                    return isManager;
                return false;
            case 'can_view_dashboard':
            case 'can_export_jobs':
            case 'can_export_employees':
            case 'can_export_service_items':
                return isManager; //should work for managers!
            case 'can_manage_facial_recognition':
                return isManager;
            case 'view_all_icons_on_activity_view':
                if(company.settings._prefs.specialLegendHiding)
                    return isManager;
                return true;
            case 'can_group_web_clock_own_group':
                if(company.settings.timeSettings.managersCanUseGroupClock)
                    return isManager;
                return false; //admins can, have have already been granted access inherently in this permission check above
/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
//  Only admins at the moment
/////////////////////////////////////////////////////////////////////////////////////////////
            case 'can_import_employees':
            case 'can_import_jobs':
            case 'can_import_service_items':
            case 'can_edit_customer_settings':
            case 'can_clear_employees_group': 
            case 'can_edit_groups':
            case 'can_edit_payroll_items':
            case 'can_access_api_settings':
            case 'can_access_login_alerts':
            case 'can_access_sso_settings':
            case 'can_edit_private_job_notes':
            case 'can_edit_private_employee_notes':
            case 'can_view_pto_settings':
            case 'can_edit_job_rules':
                return false; //admin only
            default: 
                throw new Error(`Unknown permission requested[${userPermission}]`);
        }
        return false;
    },[company, user, isManagerActual]);
        
    return { 
        isLoading: managers.isLoading || companyMethods.isLoading,        
        hasPermission
    }
}